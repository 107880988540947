import React from 'react';

function LineSeparator({text}) {

    /**
     *
     */

    return (
        <div className="pt-2 pb-4 w-full">
            <div className="relative inline-flex items-center w-full">
                <div className="w-full border-b-2 border-slate-100 dark:border-slate-800"></div>
                <span className="absolute px-4 py-1 text-xs rounded-full dark:text-slate-400 text-slate-500
                    dark:bg-slate-800 bg-slate-100 -translate-x-0 left-0">
                    {text}
                </span>
            </div>
        </div>
    );
}

export default LineSeparator;