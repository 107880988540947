import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Directual from "directual-api";
import { useAuth } from "../../../context/AuthProvider";
import { useData } from "../../../context/DataProvider";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { useForm, useFieldArray } from "react-hook-form";

const api = new Directual({apiHost: '/'});

function TeacherRegisterLesson() {

    const { id } = useParams();
    const navigate = useNavigate();

    {/* GET IDS FORM LINK */}
    const lessonID = id.split("&groupID=")[0];
    const groupID = id.split("&groupID=")[1];

    {/*
    const [lessonID, setLessonID] = useState('');
    const [groupID, setGroupID] = useState('');
    */}

    // console.log("Lesson ID: ", lessonID);
    // console.log("Group ID: ", groupID);


    {/* SET UP API REQUEST */}
    const studentsDataStructure = "bs_students_in_groups";
    const studentEndpoint = "getStudentsInGroup";

    {/* SET UP API REQUEST */}
    const lessonsDataStructure = "bs_lessons";
    const lessonsEndpoint = "getLessonById";

    {/* SET UP API REQUEST TO UPLOAD FILES */}
    const fileStorage = "FileUpload";
    const fileEndpoint = "uploadFiles";

    {/* SET UP API REQUEST TO POST REGISTRATION */}
    const lessonRegistrationDataStructure = "bs_registrations";
    const lessonRegistrationEndpoint = "postRegistration";


    {/*
        TODO: Эти даты используются для фильтрации на строне сервера: возвращается список тех студентов
        TODO: Надо проверить корректность работы
        TODO: Возможно не отображался студент на пробном дне
    */}
    const unixStartsAt = new Date().getTime();
    const unixEndsAt = unixStartsAt;

    const [lessonUnix, setLessonUnix] = useState(0);

    const auth = useAuth();
    const webUserData = useData();

    const [loading, setLoading] = useState(false);
    const [students, setStudents] = useState([]);
    const [studentsDefaultValues, setStudentsDefaultValues] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [loadingLesson, setLoadingLesson] = useState(false);
    const [lessonResponse, setLessonResponse] = useState([]);
    const [lessonData, setLessonData] = useState([]);

    {/* POSTING DATA STATES */}
    const [postData, setPostData] = useState({
        lesson_id: "",
        group_id: "",
        present_students: "",
        topics: "",
        homework: "",
        academics_data: "",
        teacher: "",
        lesson_plan_file: "",
        classwork_files: "",
        homework_files: ""
    });
    const [posting, setPosting] = useState(false);
    const [postDataResponse, setPostDataResponse] = useState();
    const [postDataStatus, setPostDataStatus] = useState();
    const [postDataBadRequest, setPostDataBadRequest] = useState();

    {/* INPUT FILES REFERENCES */}
    const lessonPlanFileInputRef = useRef();
    const classworkFilesInputRef = useRef();
    const homeworkFilesInputRef = useRef();

    {/* LESSON PLAN STATES */}
    const [lessonPlanFiles, setLessonPlanFiles] = useState([]);
    const [lessonPlanResponse, setLessonPlanResponse] = useState();
    const [lessonPlanStatus, setLessonPlanStatus] = useState();
    const [lessonPlanBadRequest, setLessonPlanBadRequest] = useState();
    const [lessonPlanLoading, setLessonPlanLoading] = useState(false);

    {/* CLASSWORK STATES */}
    const [classworkFiles, setClassworkFiles] = useState([]);
    const [classworkResponse, setClassworkResponse] = useState();
    const [classworkStatus, setClassworkStatus] = useState();
    const [classworkBadRequest, setClassworkBadRequest] = useState();
    const [classworkLoading, setClassworkLoading] = useState(false);

    {/* HOMEWORK STATES */}
    const [homeworkFiles, setHomeworkFiles] = useState([]);
    const [homeworkResponse, setHomeworkResponse] = useState();
    const [homeworkStatus, setHomeworkStatus] = useState();
    const [homeworkBadRequest, setHomeworkBadRequest] = useState();
    const [homeworkLoading, setHomeworkLoading] = useState(false);

    // API запрос на получение данных о студентах в группе
    useEffect( () => {

        // console.log("TeacherRegisterLesson: useEffect for getting students in group")
        // проверка, что полученный ID из ссылки валиден по количеству символов
        if (groupID.length === 36 && lessonUnix > 0) {

            setLoading(true);

            // console.log(`API Call ::  ${studentsDataStructure}/${studentEndpoint}`);

            api
                .structure(studentsDataStructure)
                .getData(studentEndpoint, {
                    groupID: groupID,
                    sessionID: auth.sessionID,
                    startsAt: lessonUnix,
                    endsAt: lessonUnix
                })
                .then((response) => {
                    if (response.payload.length === 0) {
                        throw "Something went wrong: getStudentsInGroup error. Group ID is not correct or there are no students in group."
                    }
                    setStudents(response.payload || []);
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                })
        } else {
            setErrMsg("Group ID is not correct so it's not possible to get list of students. Please contact administrator.")
        }
    }, [groupID, lessonUnix])

    // API запрос на получение данных об уроке
    useEffect( () => {

        // проверка, что полученный ID из ссылки валиден по количеству символов
        if (lessonID.length === 36) {

            setLoadingLesson(true);

            // console.log(`API Call ::  ${lessonsDataStructure}/${lessonsEndpoint}`);
            api
                .structure(lessonsDataStructure)
                .getData(lessonsEndpoint, {
                    lessonID: lessonID,
                    sessionID: auth.sessionID,
                })
                .then((response) => {
                    if (response.payload.length === 0) {
                        throw "Something went wrong: getLessonById error. Lesson ID is not correct."
                    }

                    setLessonResponse(response.payload || []);
                    setLoadingLesson(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoadingLesson(false);
                })
        } else {
            setErrMsg("Lesson ID is not correct so it's not possible to get lesson data. Please contact administrator.")
        }
    }, [lessonID])

    useMemo( () => {
        setLessonUnix(lessonResponse[0]?.starts_at);
    }, [lessonResponse])

    useEffect( () => {
        setLessonData(lessonResponse);
    }, [lessonResponse])

    // Создаем массив данных с дефолтными значениями для формы: название группы и список студентов с полями
    useEffect( () => {

        // console.log("TeacherRegisterLesson: useEffect for creating default values for field array")
        // console.log("students.length: ", students.length);

        if (students.length !== 0) {
            setGroupName(students[0].group.full_name || '');
            setStudentsDefaultValues(students.map( (student) => {
                    // console.log(student)
                    return {
                        studentID: student.student.id || '',
                        student: student.student.full_name || '',
                        isPresent: false,
                        didHomework: false,
                        behavior: "good",
                        awards: ""
                    }
                }
            ))
            {/*
            console.log("Setting default values for field array: ", JSON.stringify(studentsDefaultValues));
            setValue("studentsData", studentsDefaultValues);
            */}
        }
    }, [students])

    useEffect( () => {
        // console.log("Setting default values for field array: ", JSON.stringify(studentsDefaultValues));
        setValue("studentsData", studentsDefaultValues);
    }, [studentsDefaultValues])

    // Создаем JSON-строку с данными о файлах для работы в классе
    useEffect( () => {
        setValue("classworkFilesLinks", JSON.stringify(classworkFiles));
    }, [classworkFiles])

    // Создаем JSON-строку с данными о файлах для домашней работы
    useEffect( () => {
        setValue("homeworkFilesLinks", JSON.stringify(homeworkFiles));
    }, [homeworkFiles])

    const { register, setValue, handleSubmit, control, formState: { errors } } = useForm( {
        defaultValues: {
            groupID: groupID,
            lessonID: lessonID,
            topics: "",
            homework: "",
            lessonPlanFileData: {
                originalFileName: "",
                urlLink: ""
            },
            classworkFilesLinks: "",
            homeworkFilesLinks: "",
            studentsData: []
        }
    });

    const { fields } = useFieldArray({
        control,
        name: "studentsData"
    });

    const timeConverter = (unixTimestamp) => {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const dateConverter = (unixTimestamp) => {
        const a = new Date(unixTimestamp);
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'Jule',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        const hour = a.getHours();
        const min = a.getMinutes();
        return date + ' ' + month + ' ' + year;
    }

    {/* POST REQUEST TO REGISTER LESSON */}
    const onSubmit = (data) => {
        // console.log("onSubmit Ruuuuuuns");
        // console.log("React Hook Data");
        // console.log(data);

        const presentStudents = data.studentsData.filter( student =>
            student.isPresent === true
        );

        const presentStudentIDs = presentStudents.map( student => {
            return student.studentID
        });

        setPostData({
            lesson_id: lessonID,
            group_id: groupID,
            present_students: presentStudentIDs.join(","),
            topics: data.topics,
            homework: data.homework,
            academics_data: JSON.stringify(data.studentsData),
            teacher: webUserData.teacherID,
            lesson_plan_file: JSON.stringify(data.lessonPlanFileData),
            classwork_files: data.classworkFilesLinks,
            homework_files: data.homeworkFilesLinks
        });

        // console.log("MAKING POST REQUEST");

        setPosting(true);
        api
            .structure(lessonRegistrationDataStructure)
            .setData(lessonRegistrationEndpoint,
                {
                        lesson_id: lessonID,
                        group_id: groupID,
                        present_students: presentStudentIDs.join(","),
                        topics: data.topics,
                        homework: data.homework,
                        academics_data: JSON.stringify(data.studentsData),
                        teacher: webUserData.teacherID,
                        lesson_plan_file: JSON.stringify(data.lessonPlanFileData),
                        classwork_files: data.classworkFilesLinks,
                        homework_files: data.homeworkFilesLinks
                    },
                {
                    sessionID: auth.sessionID
                })
            .then((response) => {
                // console.log(response);
                setPostDataResponse(response.result);
                setPostDataStatus(response.status);
                setPosting(false);
                navigate("/teacher/british");
            })
            .catch((e) => {
                // handling errors
                setPosting(false);
                console.log(e.response);
                setPostDataBadRequest({
                    httpCode: e.response.status,
                    msg: e.response.data.msg
                })
            })

        // console.log("POST DATA");
        // console.log(postData);

    };

    const lessonPlanUpload = (e) => {

        setLessonPlanResponse(null);
        setLessonPlanLoading(true);

        const file = e.target.files[0];
        const formData = new FormData();
        formData.append(
            "file", file
        );

        if(file) {
            api
                .structure(fileStorage)
                .setData(fileEndpoint, formData,
                    {
                        sessionID: auth.sessionID,
                    })
                .then((response) => {

                    {/*
                    [{"file":{"originalFileName":"artboard_2.webp",
                    "urlLink":"https://api.directual.com/fileUploaded/studylab/7340c21e-f7f0-406a-9c09-bcb9093144c8.webp"}}]
                    */}

                    setLessonPlanResponse(response.result)
                    setLessonPlanStatus(response.status)
                    setValue("lessonPlanFileData", response.result[0].file);
                    setLessonPlanLoading(false);
                })
                .catch((e) => {
                    setLessonPlanLoading(false);
                    console.log(e.response);
                    setLessonPlanBadRequest({
                        httpCode: e.response.status,
                        msg: e.response.data.msg
                    })
                })
        } else {
            setLessonPlanLoading(false);
        }
    }
    const deleteLessonPlanFile = () => {
        setLessonPlanResponse(null);
        lessonPlanFileInputRef.current.value = null;
        setValue("lessonPlanFileData", {
            originalFileName: "",
            urlLink: ""
        });
    }

    const classworkUpload = (e) => {

        setClassworkResponse(null);
        setClassworkLoading(true);

        const files = e.target.files;
        const filesArray = [...files];

        filesArray.map( file => {

            let formData = new FormData();
            formData.append(
                "file", file
            );

            if (file) {
                api
                    .structure(fileStorage)
                    .setData(fileEndpoint, formData,
                        {
                            sessionID: auth.sessionID,
                        })
                    .then((response) => {
                        // console.log("success");
                        // console.log(response.result[0].file);
                        setClassworkResponse(response.result);
                        setClassworkStatus(response.status);
                        setClassworkFiles(prevArray => [...prevArray, response.result[0].file]);
                        setClassworkLoading(false);
                    })
                    .catch((e) => {
                        setClassworkLoading(false);
                        console.log(e.response);
                        setClassworkBadRequest({
                            httpCode: e.response.status,
                            msg: e.response.data.msg
                        })
                    })
            } else {
                setClassworkLoading(false);
            }
        })
    }
    const removeClassworkFile = (index) => {

        classworkFiles.splice(index, 1);
        setClassworkFiles([...classworkFiles]);

        if (classworkFiles.length === 0) {
            setClassworkResponse(null);
            classworkFilesInputRef.current.value = null;
            // setClassworkFiles([]);
            setValue("classworkFilesLinks", "");
        }

        // setValue("classworkFilesLinks", JSON.stringify(classworkFiles));

    }

    const homeworkUpload = (e) => {

        setHomeworkResponse(null);
        setHomeworkLoading(true);

        const files = e.target.files;
        const filesArray = [...files];

        filesArray.map( file => {

            let formData = new FormData();
            formData.append(
                "file", file
            );

            if (file) {
                api
                    .structure(fileStorage)
                    .setData(fileEndpoint, formData,
                        {
                            sessionID: auth.sessionID,
                        })
                    .then((response) => {
                        // console.log("success");
                        // console.log(response.result[0].file);
                        setHomeworkResponse(response.result);
                        setHomeworkStatus(response.status);
                        setHomeworkFiles(prevArray => [...prevArray, response.result[0].file]);
                        setHomeworkLoading(false);
                    })
                    .catch((e) => {
                        setHomeworkLoading(false);
                        console.log(e.response);
                        setHomeworkBadRequest({
                            httpCode: e.response.status,
                            msg: e.response.data.msg
                        })
                    })
            } else {
                setHomeworkLoading(false);
            }
        })
    }
    const removeHomeworkFile = (index) => {

        homeworkFiles.splice(index, 1);
        setHomeworkFiles([...homeworkFiles]);

        if (homeworkFiles.length === 0) {
            setHomeworkResponse(null);
            homeworkFilesInputRef.current.value = null;
            setValue("homeworkFilesLinks", "");
        }

        // setValue("classworkFilesLinks", JSON.stringify(classworkFiles));

    }

    const timeNow = new Date().getTime();

    return (
        <>
        <form onSubmit={handleSubmit(onSubmit)} className={"xl: px-20"}>
            {(!loading || !loadingLesson)?
                (
                    <>
                        <div className="flex flex-row justify-between items-center">
                            <h1 className={"text-3xl dark:text-slate-200 mt-8 font-medium mb-4"}>
                                Lesson Registration
                            </h1>
                            <button type="button" onClick={() => {navigate("/teacher/british")}}
                                    className="aspect-square text-indigo-700 border border-indigo-700 hover:bg-indigo-700
                                    hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300
                                    font-medium rounded-lg text-sm p-4 text-center inline-flex items-center
                                    dark:border-indigo-500 dark:text-indigo-500
                                    dark:hover:text-white dark:focus:ring-indigo-800">
                                <svg aria-hidden="true" className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </div>
                        <h1 className={"text-lg dark:text-slate-200 text-slate-900 pt-2"}>
                            {groupName}
                        </h1>
                        <div className="flex flex-row gap-x-4 pb-6">
                            <p className="dark:text-indigo-500 text-indigo-600 text-base">
                                {dateConverter(lessonData[0]?.starts_at)}
                            </p>
                            <p className="dark:text-indigo-500 text-indigo-600 text-base">
                                {timeConverter(lessonData[0]?.starts_at)} – {timeConverter(lessonData[0]?.starts_at + lessonData[0]?.duration*60*1000)}
                            </p>
                            <p className="dark:text-indigo-500 text-indigo-600 text-base">
                                {lessonData[0]?.subject?.subject}
                            </p>
                        </div>
                        <p className="dark:text-indigo-300 text-slate-400 text-xs py-0.5">
                            <strong>Lesson ID:</strong> {lessonID}
                        </p>

                        {/**/}
                        <p className="dark:text-indigo-300 text-slate-400 text-xs py-0.5">
                            <strong>Lesson UNIX:</strong> {lessonUnix}
                        </p>

                        <p className="dark:text-indigo-300 text-slate-400 text-xs pt-0.5 pb-8">
                            <strong>Group ID:</strong> {groupID}
                        </p>
                        {lessonData[0]?.registered?
                            (<div
                                className="p-4 text-sm text-slate-700 bg-slate-100 rounded-lg
                                dark:bg-slate-700 dark:text-slate-300">
                                This lesson has already been registered.
                            </div>)
                            : (
                                <div>
                                    {/* LESSON PLAN FILE UPLOAD */}
                                    {lessonData[0]?.starts_at < timeNow &&
                                    <div className="border-y-2 border-slate-200 dark:border-slate-800 py-6 border-dotted">
                                        <label className="block pb-2 text-sm font-base text-slate-900 dark:text-slate-400"
                                               htmlFor="lesson_plan">Upload lesson plan</label>
                                        <input onChange={lessonPlanUpload} ref={lessonPlanFileInputRef}
                                               className="block w-full text-xs text-slate-400 cursor-pointer
                                               file:mr-4 file:py-2 file:px-4
                                               file:rounded-lg file:border-0 file:text-sm file:font-base
                                               file:bg-slate-50 file:text-indigo-700
                                               dark:file:bg-slate-800 dark:file:text-slate-300
                                               dark:hover:file:bg-slate-700 dark:hover:file:text-white
                                               hover:file:bg-indigo-100"
                                               type="file" id="lesson_plan" name="lesson_plan"/>
                                        <div className="text-xs pt-2 flex flex-row">
                                            {lessonPlanLoading &&
                                                <p className="text-indigo-400">Uploading file ...</p>
                                            }
                                            {lessonPlanResponse &&
                                                <div className="flex flex-row gap-x-2 items-center">
                                                    <p className="text-slate-900 dark:text-slate-400">Uploaded file:</p>
                                                    <div className="flex flex-row gap-x-2 items-center bg-yellow-100 py-0.5 rounded-lg px-2">
                                                        <a href={lessonPlanResponse[0].file.urlLink} rel="noreferrer" className="underline"
                                                           target="_blank">
                                                            {lessonPlanResponse[0].file.originalFileName}
                                                        </a>
                                                        <svg onClick={deleteLessonPlanFile} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                             fill="currentColor" className="w-3 h-3 cursor-pointer">
                                                            <path fillRule="evenodd"
                                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                                                  clipRule="evenodd"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    }

                                    {/* HEADERS FOR STUDENT ARRAY FIELDS */}
                                    <div className="pt-12 grid grid-cols-5 gap-4 dark:border-b-2 dark:border-b-slate-800
                                    border-b-slate-100 border-b-2">
                                        <div className="text-xs font-base text-slate-400 dark:text-slate-500 pb-1 pl-2 text-left">
                                            Student
                                        </div>
                                        <div className="text-xs font-base text-slate-400 dark:text-slate-500 pb-1 text-center">
                                            Attendance
                                        </div>
                                        <div className="text-xs font-base text-slate-400 dark:text-slate-500 pb-1 text-center">
                                            Homework
                                        </div>
                                        <div className="text-xs font-base text-slate-400 dark:text-slate-500 pb-1 text-center">
                                            Behaviour
                                        </div>
                                        <div className="text-xs font-base text-slate-400 dark:text-slate-500 pb-1 text-center">
                                            Awards
                                        </div>
                                    </div>

                                    {/*
                                    <p className="text-xs dark:text-yellow-400 py-2">{JSON.stringify(fields)}</p>
                                    */}

                                    {/* ARRAY FIELDS WITH ACADEMIC DATA */}
                                    {fields.map((field, index) => (
                                        <div key={field.id} className="flex row p-2 rounded-lg grid grid-cols-5 gap-4 items-center">
                                            <p className="text-sm dark:text-slate-200 py-0.5">
                                                {field.student}
                                            </p>

                                            <div className="flex justify-center">
                                                <input type="checkbox" {...register(`studentsData.${index}.isPresent`)}
                                                className="w-4 h-4 text-indigo-600 bg-slate-100 rounded border-slate-300
                                                focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-slate-800
                                                focus:ring-2 dark:bg-slate-700 dark:border-slate-600"/>
                                            </div>

                                            <div className="flex justify-center">
                                                <input type="checkbox" {...register(`studentsData.${index}.didHomework`)}
                                                       className="w-4 h-4 text-indigo-600 bg-slate-100 rounded border-slate-300
                                                focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-slate-800
                                                focus:ring-2 dark:bg-slate-700 dark:border-slate-600"/>
                                            </div>

                                            <select className="bg-slate-50 border border-slate-300 text-slate-900
                                            text-xs rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block
                                            w-full px-2.5 py-1 dark:bg-slate-700 dark:border-slate-600
                                            dark:placeholder-slate-400 dark:text-white dark:focus:ring-indigo-500
                                            dark:focus:border-indigo-500"
                                                defaultValue={"good"}
                                                {...register(`studentsData.${index}.behavior`)}>
                                                <option value={"good"}>
                                                    Good
                                                </option>
                                                <option value={"issue"}>
                                                    Disruptive
                                                </option>
                                            </select>

                                            <select className="bg-slate-50 border border-slate-300 text-slate-900
                                            text-xs rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block
                                            w-full px-2.5 py-1 dark:bg-slate-700 dark:border-slate-600
                                            dark:placeholder-slate-400 dark:text-white dark:focus:ring-indigo-500
                                            dark:focus:border-indigo-500"
                                                    defaultValue={""}
                                                    {...register(`studentsData.${index}.awards`)}>
                                                <option value={""}>
                                                </option>
                                                <option value={"effort"}>
                                                    Great Effort
                                                </option>
                                                <option value={"homework"}>
                                                    Homework Hero
                                                </option>
                                            </select>

                                        </div>
                                    ))}

                                    {/* COVERED TOPICS FIELDS*/}
                                    {lessonData[0]?.starts_at < timeNow &&
                                        <>
                                            <label htmlFor="topics" className="block pt-12 mb-1 text-sm font-base
                                            text-slate-900 dark:text-slate-400">
                                            Covered topics *
                                            </label>
                                            <textarea {...register("topics", {required: true})}
                                                      id="topics" rows="2"
                                                      className="block p-2.5 w-full text-sm text-slate-900 bg-slate-50 rounded-lg
                                                      border border-slate-300
                                                      focus:ring-indigo-500 focus:border-indigo-500
                                                      dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400
                                                      dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                      placeholder="Topics ...">
                                            </textarea>
                                            {errors.topics &&
                                                <p className="text-xs pt-0.5 font-base text-red-500">
                                                    Sorry, topics are required
                                                </p>}
                                        </>
                                    }

                                    {/* CLASSWORK FILES UPLOAD */}
                                    {lessonData[0]?.starts_at < timeNow &&
                                        <>
                                            <label className="block pt-6 mb-1 text-sm font-base text-slate-900 dark:text-slate-400"
                                                   htmlFor="classwork">Upload classwork files</label>
                                            <input onChange={classworkUpload} ref={classworkFilesInputRef}
                                                   className="block w-full text-xs text-slate-400 cursor-pointer
                                                       file:mr-4 file:py-2 file:px-4
                                                       file:rounded-lg file:border-0 file:text-sm file:font-base
                                                       file:bg-slate-50 file:text-indigo-700
                                                       dark:file:bg-slate-800 dark:file:text-slate-300
                                                       dark:hover:file:bg-slate-700 dark:hover:file:text-white
                                                       hover:file:bg-indigo-100"
                                                   type="file" multiple id="classwork" name="classwork"/>
                                            <div className="text-xs pt-2 flex flex-row">
                                                {classworkLoading &&
                                                    <p className="text-indigo-400">Uploading file ... </p>
                                                }
                                                {classworkFiles.length > 0 &&
                                                    <div className="flex flex-row gap-x-2 items-center">
                                                        <p className="text-slate-900 dark:text-slate-400">Uploaded&nbsp;files:</p>

                                                        <div className="flex flex-row flex-wrap gap-x-2 gap-y-1">
                                                            {classworkFiles.map( (file, index) => (
                                                                <div key={index} className="flex flex-row gap-x-2 items-center bg-yellow-100 py-0.5 rounded-lg px-2">
                                                                    <a href={file.urlLink} rel="noreferrer" className="underline"
                                                                       target="_blank">
                                                                        {file.originalFileName}
                                                                    </a>

                                                                    <svg onClick={ () => removeClassworkFile(index)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                                         fill="currentColor" className="w-3 h-3 cursor-pointer">
                                                                        <path fillRule="evenodd"
                                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                                                              clipRule="evenodd"/>
                                                                    </svg>

                                                                </div>
                                                            ))
                                                            }
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }

                                    {/* HOMEWORK FIELDS */}
                                    {lessonData[0]?.starts_at < timeNow &&
                                        <>
                                            <label htmlFor="homework" className="block pt-12 mb-1 text-sm font-base text-slate-900 dark:text-slate-400">
                                                Next lesson homework *
                                            </label>
                                            <textarea {...register("homework", {required: true})}
                                                id="homework" rows="3"
                                                      className="block p-2.5 w-full text-sm text-slate-900 bg-slate-50 rounded-lg
                                                      border border-slate-300
                                                      focus:ring-indigo-500 focus:border-indigo-500
                                                      dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400
                                                      dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                      placeholder="Homework ...">
                                            </textarea>
                                            {errors.homework &&
                                                <p className="text-xs pt-0.5 font-base text-red-500">
                                                    If you don't have homework for next lesson, write "No homework"
                                                </p>}
                                        </>
                                    }

                                    {/* HOMEWORK FILES UPLOAD */}
                                    {lessonData[0]?.starts_at < timeNow &&
                                        <>
                                            <label className="block pt-6 mb-1 text-sm font-base text-slate-900 dark:text-slate-400"
                                                   htmlFor="homework">Upload homework files</label>
                                            <input onChange={homeworkUpload} ref={homeworkFilesInputRef}
                                                   className="block w-full text-xs text-slate-400 cursor-pointer
                                                       file:mr-4 file:py-2 file:px-4
                                                       file:rounded-lg file:border-0 file:text-sm file:font-base
                                                       file:bg-slate-50 file:text-indigo-700
                                                       dark:file:bg-slate-800 dark:file:text-slate-300
                                                       dark:hover:file:bg-slate-700 dark:hover:file:text-white
                                                       hover:file:bg-indigo-100"
                                                   type="file" multiple id="homework" name="homework"/>
                                            <div className="text-xs pt-2 flex flex-row">
                                                {homeworkLoading &&
                                                    <p className="text-indigo-400">Uploading file ... </p>
                                                }
                                                {homeworkFiles.length > 0 &&
                                                    <div className="flex flex-row gap-x-2 items-center">
                                                        <p className="text-slate-900 dark:text-slate-400">Uploaded&nbsp;files:</p>

                                                        <div className="flex flex-row flex-wrap gap-x-2 gap-y-1">
                                                            {homeworkFiles.map( (file, index) => (
                                                                <div key={index} className="flex flex-row gap-x-2 items-center bg-yellow-100 py-0.5 rounded-lg px-2">
                                                                    <a href={file.urlLink} rel="noreferrer" className="underline"
                                                                       target="_blank">
                                                                        {file.originalFileName}
                                                                    </a>

                                                                    <svg onClick={ () => removeHomeworkFile(index)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                                         fill="currentColor" className="w-3 h-3 cursor-pointer">
                                                                        <path fillRule="evenodd"
                                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                                                              clipRule="evenodd"/>
                                                                    </svg>

                                                                </div>
                                                            ))
                                                            }
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }

                                    {lessonData[0]?.starts_at < timeNow?
                                        !posting?
                                            <button className="mt-12 text-white bg-indigo-800 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300
                                                font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-12 dark:bg-indigo-800 dark:hover:bg-indigo-700
                                                focus:outline-none dark:focus:ring-indigo-800">
                                                Register
                                            </button>
                                            :
                                            <div role="status" className="my-12 flex flex-row items-center gap-x-1">
                                                <svg aria-hidden="true"
                                                     className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600"
                                                     viewBox="0 0 100 101" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"/>
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"/>
                                                </svg>
                                                <span className="text-indigo-600 font-base text-sm">Loading...</span>
                                            </div>
                                        :
                                        <div
                                            className="p-4 mt-16 text-sm text-slate-700 bg-slate-100 rounded-lg
                                dark:bg-slate-700 dark:text-slate-300">
                                            You can register a lesson only after it has started.
                                        </div>
                                    }

                                </div>
                            )
                        }
                    </>
                )
                : <LoadingSpinner />
            }
        </form>

            {/*
        <p className="pt-24 text-xs dark:text-slate-400">{JSON.stringify(studentsDefaultValues)}</p>
        <p className="pt-24 text-xs dark:text-slate-400">{JSON.stringify(students)}</p>
        */}

        </>
    );
}

export default TeacherRegisterLesson;