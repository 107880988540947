import React, {useEffect, useMemo, useState} from 'react';
import BsCalendar from "./BsCalendar";
import { useParams, Link } from "react-router-dom";
import { useData } from "../../../context/DataProvider";
import { useAuth } from "../../../context/AuthProvider";
import Directual from "directual-api";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import BsAcademics from "./BsAcademics";
import BsReports from "./BsReports";

const api = new Directual({apiHost: '/'});

const BsTabs = () => {

    const timeNow = new Date().getTime();

    {/* GET IDS FORM LINK */}
    const { id } = useParams();
    const clientID = id.split("&groupID=")[0];
    const groupID = id.split("&groupID=")[1];

    // console.log("clientID: ", clientID);
    // console.log("groupID: ", groupID);

    {/* SET UP API REQUEST */}
    const studentsInGroupDataStructure = "bs_students_in_groups";
    const studentsInGroupEndpoint = "getBsDataByStudentAndGroup";

    const data = useData();
    const auth = useAuth();

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [studentsInGroupLoading, setStudentsInGroupLoading] = useState(false);
    const [studentsInGroupResponse, setStudentsInGroupResponse] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [bsYearId, setBsYearId] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [trialDate, setTrialDate] = useState(null);

    const [errorMessage, setErrorMessage] = useState("");

    const [studentData, setStudentData] = useState([]);
    const [calendar, setCalendar] = useState([]);

    const [calendarByDate, setCalendarByDate] = useState([]);

    const [filteredCalendar, setFilteredCalendar] = useState([]);
    const [upcomingToggleState, setUpcomingToggleState] = useState(true);
    const [upcomingCalendar, setUpcomingCalendar] = useState([]);

    const byDate = timeNow > endDate? endDate : timeNow

    {/* API REQUEST TO GET ALL BS DATA REGARDING STUDENT IN GROUP
     FIXED: at 01:00 can't see today's lessons that starts at 10:00 end ends at 11:50
     FIXED: this is for Mikhail Tester, day 24 September 2022, Week 4
     SOLUTION: in D for calendar item we need to set date & time,
     where time = time when the last lesson of the day ends
     */}
    useEffect( () => {
        // console.log("API CALL");

        if (groupID.length === 36 && clientID.length === 36) {
            setStudentsInGroupLoading(true);
            api
                .structure(studentsInGroupDataStructure)
                .getData(studentsInGroupEndpoint, {
                    groupID: groupID,
                    sessionID: auth.sessionID,
                    clientID: clientID
                })
                .then((response) => {
                    if (response.payload.length === 0) {
                        throw "Something went wrong: getStudentsInGroup error. Group ID is not correct."
                    }
                    setStudentsInGroupResponse(response.payload || []);

                    setCalendar(response.payload[0].group.calendar);
                    setStartDate(response.payload[0].starts_at);
                    setEndDate(response.payload[0].ends_at);
                    setTrialDate(response.payload[0].trial_date);
                    setGroupName(response.payload[0].group.full_name);
                    setBsYearId(response.payload[0].group.bs_year.id);

                    setStudentsInGroupLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setStudentsInGroupLoading(false);
                })
        } else {
            setErrorMessage("Group ID or Client ID is not correct so it's not possible to get data. Please contact administrator.")
        }
    }, [clientID, groupID])


    {/* FILTERING CALENDAR TO START & END DATES */}
    useMemo( () => {
        // console.log("useMemo Runs");
        setFilteredCalendar(calendar.filter( day =>
            (day.date >= startDate && day.date <= endDate)
        ))

    }, [studentsInGroupResponse])

    useMemo( () => {
        // console.log("useMemo Runs");
        setCalendarByDate(calendar.filter( day =>
            (day.date <= byDate)
        ))

    }, [studentsInGroupResponse])

    {/* SHOWING UPCOMING CALENDAR */}
    useMemo( () => {

        // console.log(upcomingToggleState);

        if(upcomingToggleState === true) {
            setUpcomingCalendar(
                filteredCalendar.filter(
                    day => day.date >= timeNow
                )
            )
        } else {
            setUpcomingCalendar(filteredCalendar)
        }

    }, [upcomingToggleState, filteredCalendar])

    { /* FILTERING DATA TO A SPECIFIC STUDENT */ }
    useEffect( () => {
        // console.log("Filtering students");
        setStudentData(
            data.clientData.filter( client => client.id === clientID )
        );
    }, [id]);


    const setActiveTab = (index) => {
        setActiveTabIndex(index);
    }

    const dateConverter = (unixTimestamp) => {
        const a = new Date(unixTimestamp);
        const months = ['January','February','March','April','May','June','Jule','August','September','October','November','December'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        // const hour = a.getHours();
        // const min = a.getMinutes();
        return date + ' ' + month + ' ' + year;
    }

    const timeConverter = (unixTimestamp) => {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute:'2-digit'
        });
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row gap-x-4 gap-y-0.5 lg:items-center my-4">
                <h1 className={"text-2xl dark:text-slate-200 font-medium"}>
                    {studentData[0]?.full_name}
                </h1>
                <h1 className={"text-2xl dark:text-indigo-400 text-indigo-600 font-medium lg:font-light"}>
                    {groupName}
                </h1>
            </div>

            {/* TABS */}
            <div className="mb-4 border-b border-slate-200 dark:border-slate-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                    <li className="mr-2">
                        <button
                            className={activeTabIndex === 0
                                ? "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 text-indigo-600 hover:text-indigo-600 dark:text-indigo-500 dark:hover:text-indigo-500 border-indigo-600 dark:border-indigo-500"
                                : "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 border-transparent text-slate-500 border-slate-100 hover:text-slate-600 hover:border-slate-300 dark:hover:text-slate-300 dark:border-transparent dark:text-slate-400 dark:border-slate-700"
                            }
                            onClick={() => setActiveTab(0)}
                        >
                            Calendar
                        </button>
                    </li>
                    <li className="mr-2">
                        <button
                            className={activeTabIndex === 1
                                ? "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 text-indigo-600 hover:text-indigo-600 dark:text-indigo-500 dark:hover:text-indigo-500 border-indigo-600 dark:border-indigo-500"
                                : "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 border-transparent text-slate-500 border-slate-100 hover:text-slate-600 hover:border-slate-300 dark:hover:text-slate-300 dark:border-transparent dark:text-slate-400 dark:border-slate-700"
                            }
                            onClick={() => setActiveTab(1)}
                        >
                            Academics
                        </button>
                    </li>
                    <li className="mr-2">
                        <button
                            className={activeTabIndex === 2
                                ? "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 text-indigo-600 hover:text-indigo-600 dark:text-indigo-500 dark:hover:text-indigo-500 border-indigo-600 dark:border-indigo-500"
                                : "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 border-transparent text-slate-500 border-slate-100 hover:text-slate-600 hover:border-slate-300 dark:hover:text-slate-300 dark:border-transparent dark:text-slate-400 dark:border-slate-700"
                            }
                            onClick={() => setActiveTab(2)}
                        >
                            Reports
                        </button>
                    </li>

                    {/* PROFILE TAB
                    <li>
                        <button
                            className={activeTabIndex === 3
                                ? "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 text-indigo-600 hover:text-indigo-600 dark:text-indigo-500 dark:hover:text-indigo-500 border-indigo-600 dark:border-indigo-500"
                                : "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 border-transparent text-slate-500 border-slate-100 hover:text-slate-600 hover:border-slate-300 dark:hover:text-slate-300 dark:border-transparent dark:text-slate-400 dark:border-slate-700"
                            }
                            onClick={() => setActiveTab(3)}
                        >
                            Profile
                        </button>
                    </li>
                    */}

                </ul>
            </div>

            {/* CONTENT */}
            {!studentsInGroupLoading ?
                <div>
                    <div className={activeTabIndex === 0
                        ? ""
                        : "hidden transition duration-300"}
                    >
                        <BsCalendar
                            calendarData={upcomingCalendar}
                            calendarUpcoming={upcomingToggleState}
                            setCalendarUpcoming={setUpcomingToggleState}
                            trialDate={trialDate}
                        />
                    </div>
                    <div className={activeTabIndex === 1
                        ? ""
                        : "hidden transition duration-300"}
                    >
                        <BsAcademics
                            groupID={groupID}
                            clientID={clientID}
                            startDate={startDate}
                            endDate={endDate}
                            calendarData={calendarByDate}
                        />
                    </div>
                    <div className={activeTabIndex === 2
                        ? ""
                        : "hidden transition duration-300"}
                    >
                        <BsReports
                            clientID={clientID}
                            bsYearID={bsYearId}
                        />
                    </div>

                    {/* PROFILE CONTENT
                    <div className={activeTabIndex === 3
                        ? "p-4 bg-slate-50 transition duration-300 rounded-lg dark:bg-slate-800"
                        : "hidden transition duration-300"}
                    >
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            <strong
                                className="font-medium text-gray-800 dark:text-white">Reports tab's associated
                                content</strong>.
                            Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript
                            swaps
                            classes to control the content visibility and styling.</p>
                    </div>
                    */}
                </div>
                : <LoadingSpinner/>
            }
        </>
    );
};

export default BsTabs;