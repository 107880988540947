/**
 * Все вспомогательные функции, которые используются в проекте
 * Преобразование дат, времени и пр.
 * */

export default class AppHelpers {

    /**
     * Преобразует дату в формате Unix Timestamp в формат "HH:MM"
     * */
    static timeConverter(unixTimestamp) {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    /**
     * Преобразует дату в формате Unix Timestamp в формат даты "DD MMMM YYYY"
     * */
    static dateConverter(unixTimestamp) {
        const a = new Date(unixTimestamp);
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        // const hour = a.getHours();
        // const min = a.getMinutes();
        return date + ' ' + month + ' ' + year;
    }
}