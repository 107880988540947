import React from 'react';

function InformationBlock({message, optionClasses}) {
    return (
        <div className={`p-4 text-sm text-slate-800 bg-slate-100 rounded-lg dark:bg-slate-800 dark:text-slate-200 ${optionClasses}`}>
            {message}
        </div>
    );
}

export default InformationBlock;