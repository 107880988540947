import React from 'react';
import { Link } from "react-router-dom";

function TeacherLesson({ lesson, registered }) {

    const timeConverter = (unixTimestamp) => {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const dateConverter = (unixTimestamp) => {
        const a = new Date(unixTimestamp);
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        const hour = a.getHours();
        const min = a.getMinutes();
        return date + ' ' + month + ' ' + year;
    }

    const timeNow = new Date().getTime();

    return (
        <>
            <div className="flex flex-row gap-x-4 px-5 py-3 rounded-lg
            my-2 lg:w-4/5 w-full bg-slate-50 items-center dark:bg-slate-800">

                <div className="flex flex-col pr-4 w-1/3 gap-y-0.5">
                    <p className="text-sm font-base text-slate-900 dark:text-slate-50">
                        {dateConverter(lesson.starts_at)}
                    </p>
                    <p className="text-sm font-base text-slate-900 dark:text-slate-50">
                        {timeConverter(lesson.starts_at)} – {timeConverter(lesson.starts_at + lesson.duration*60*1000)}
                    </p>
                </div>

                <div className="flex flex-col pr-4 w-1/3 gap-y-0.5">
                    <p className="text-sm font-base text-slate-900 dark:text-slate-200">
                        {lesson.calendar.bs_group.full_name}
                    </p>
                    <p className="text-sm dark:text-indigo-400">
                        {lesson.subject.subject}
                    </p>
                </div>

                <div className="w-1/5 items-center">
                    {registered
                        ?
                        <Link to={`update/${lesson.id}`}
                              className="flex items-center justify-center gap-x-2 mx-3
                                  py-2 px-3 text-sm font-base text-center
                                  rounded-lg border border-indigo-600
                                  text-indigo-600 bg-white
                                  hover:bg-white hover:text-indigo-700
                                  focus:outline-none focus:ring-4 focus:ring-slate-200
                                  dark:bg-slate-800 dark:text-slate-300 dark:border-indigo-600
                                  dark:hover:bg-indigo-600 dark:hover:text-white dark:focus:ring-slate-700
                                  transition duration-300">
                            Update
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-3 h-3">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"/>
                            </svg>

                        </Link>
                        : lesson.starts_at <= timeNow
                            ?
                            <Link to={`register/${lesson.id}&groupID=${lesson.calendar.bs_group.id}`}
                                  className="flex items-center justify-center gap-x-2 mx-3
                                  py-2 px-3 text-sm font-base text-center
                                  text-white bg-indigo-700 rounded-lg hover:bg-indigo-800
                                  focus:ring-4 focus:outline-none focus:ring-indigo-300
                                  dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800
                                  transition duration-300
                                  ">
                                Register
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                     stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"/>
                                </svg>
                            </Link>
                            :
                            <Link to={`register/${lesson.id}&groupID=${lesson.calendar.bs_group.id}`}
                                  className="flex items-center justify-center gap-x-2 mx-3
                                  py-2 px-3 text-sm font-base text-center
                                  rounded-lg border border-slate-200
                                  text-slate-900 bg-white
                                  hover:bg-white hover:text-indigo-700
                                  focus:outline-none focus:ring-4 focus:ring-slate-200
                                  dark:bg-slate-800 dark:text-slate-300 dark:border-slate-600
                                  dark:hover:bg-slate-700 dark:hover:text-white dark:focus:ring-slate-700
                                  transition duration-300">
                                View details
                            </Link>
                    }
                </div>
            </div>
            {/*
            <p className="dark:text-slate-500 pt-2 pb-8 text-xs">
                {JSON.stringify(lesson)}
            </p>
            */}
        </>
    );
}

export default TeacherLesson;