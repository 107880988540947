import React from 'react';

function BsReport({reportData}) {

    const dateConverter = (unixTimestamp) => {
        const a = new Date(unixTimestamp);
        const months = ['January','February','March','April','May','June','Jule','August','September','October','November','December'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        // const hour = a.getHours();
        // const min = a.getMinutes();
        return date + ' ' + month + ' ' + year;
    }

    return (
        <div className="flex flex-row max-w-sm justify-between items-start">
            <div className="flex flex-col">
                <p className="text-sm font-medium text-slate-900 dark:text-slate-50">
                    {reportData.report_name || 'Report not found'}
                </p>
                <p className="pt-1 text-xs text-gray-500 dark:text-gray-400">
                    {dateConverter(reportData.report_date)}
                </p>
            </div>
            <a className="text-sm text-gray-500 dark:text-indigo-400 inline-flex align-middle gap-x-2 items-center"
               href={reportData.report_url || 'https://studylab.ru'} target="_blank">
                Download
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
            </a>
        </div>
    );
}

export default BsReport;