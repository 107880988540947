import React, {useEffect, useState } from 'react';
import HomeCardToMakeRequest from "./Home/HomeCardToMakeRequest";

import { useData } from "../../context/DataProvider";
import HomeActiveServiceBsCard from "./Home/HomeActiveServiceBsCard";
import HomeActiveServiceScCard from "./Home/HomeActiveServiceScCard";

function ClientHome() {

    const { clientData } = useData();

    const bsData = clientData.filter( client =>
        client.british_school_experinece
    );

    // console.log("DATA PROVIDER");
    // console.log(clientData);

    const scData = clientData.filter( client =>
        client.groups_experience
    );

    const scDataExample = [
        {
            "first_name":"Ivan",
            "full_name":"Ivan Tester",
            "initials":"IT",
            "groups_experience":[
                {
                "start_date":1675198800000,
                "number_of_lessons":100,
                "group_type": {
                    "group_type":"QA","id":"ee74d702-5234-42d9-ae85-0fac6e2a4c76"
                },
                "id":"5e5a8a3c-3720-4e94-90fb-290199aa0833",
                "group_name":"Test Group",
                "end_date":1693429200000,
                "is_hidden":false,
                "is_cancelled":false
                }],
            "family_member":
                {
                    "member":"Child",
                    "id":"25710b8d-7f64-4d65-966a-22d66b8051a4"
                },
            "id":"1567bee8-079a-433f-b44b-39e5ac7328ad",
            "date_of_birth":1489536000000,
            "british_school_experinece":
                [{"bs_year":{"is_active":true,"starts_at":1661979600000,"academic_year":"2022-2023","academic_name":"Test British School","id":"b8b521cf-aac8-477c-8ba1-6a82e10eb21d","is_hidden":false,"ends_at":1664755200000},"number_of_lessons":120,"full_name":"TS22 ST Reception 1","id":"3d200d0e-8696-4f30-82e0-36d697d2a8d3","group_name":"Reception 1"}],"last_name":"Tester"},{"first_name":"Alina","full_name":"Alina Tester","initials":"AT","groups_experience":[{"start_date":1675198800000,"number_of_lessons":100,"group_type":{"group_type":"QA","id":"ee74d702-5234-42d9-ae85-0fac6e2a4c76"},"id":"5e5a8a3c-3720-4e94-90fb-290199aa0833","group_name":"Test Group","end_date":1693429200000,"is_hidden":false,"is_cancelled":false}],"family_member":{"member":"Child","id":"25710b8d-7f64-4d65-966a-22d66b8051a4"},"id":"2a46155e-4b4d-44ba-b99c-f3a8ac686006","date_of_birth":1121385600000,"british_school_experinece":[],"last_name":"Tester"}]

    return (

            <>
                <h1 className="text-xl font-normal text-slate-400
                            md:text-2xl dark:text-slate-300 pt-1 pb-2">
                    All Active Services
                </h1>

                {/*
                    'sm': '640px',
                    'md': '768px',
                    'lg': '1024px',
                    'xl': '1280px',
                    '2xl': '1536px'
                */}

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3
                gap-6 pt-4">

                    {/* BRITISH SCHOOL EXPERIENCE */}
                    {bsData.map( (client, index) =>
                        client.british_school_experinece.map( (year, index) =>
                            year.bs_year.is_active &&
                                <HomeActiveServiceBsCard key={index}
                                    academicName={year.bs_year.academic_name}
                                    academicYear={year.bs_year.academic_year}
                                    clientId={client.id}
                                    groupId={year.id}
                                    student={client.full_name}
                                />
                        )
                    )}

                    {/* GROUPS EXPERIENCE */}
                    {scData.map( (client, index) =>
                        client.groups_experience.map( (group, index) =>
                            !group.is_hidden &&
                            <HomeActiveServiceScCard key={index}
                                                     academicName={group.group_name}
                                                     academicYear={group.group_type.group_type}
                                                     startDate={group.start_date}
                                                     endDate={group.end_date}
                                                     clientId={client.id}
                                                     groupId={group.id}
                                                     student={client.full_name}
                            />
                        )
                    )}

                    {/*
                    <HomeCardToMakeRequest />
                    */}

                </div>

                {/*
                <p className="pt-8 dark:text-slate-200 font-light text-sm">{JSON.stringify(scData)}</p>
                */}

            </>

    );
}

export default ClientHome;