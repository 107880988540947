import React, { useEffect, useState } from 'react';
import Directual from "directual-api";
import { useAuth } from "../../../context/AuthProvider";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import BsAcademicsLesson from "./BsAcademicsLesson";

const api = new Directual({apiHost: '/'});

function BsAcademics({groupID, clientID, startDate, endDate, calendarData}) {

    const timeNow = new Date().getTime();
    const pageSizeApiResponse = 128;
    const auth = useAuth();

    {/* SET UP API REQUEST */}
    const lessonRegistrationsStructure = "bs_registrations";
    const lessonRegistrationsEndpoint = "getLessonDataByGroupAndDate";

    const [registrationsLoading, setRegistrationsLoading] = useState(false);
    const [lessonRegistrations, setLessonRegistrations] = useState([]);
    const [calendarByDate, setCalendarByDate] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [homeworkToggle, setHomeworkToggle] = useState(true);
    const homeworkToShow = 3;

    {/* API CALL TO GET ALL REGISTRATION DATA FOR GROUP BY DATE  */}
    useEffect( () => {

        if (groupID.length === 36) {
            setRegistrationsLoading(true);

            api
                .structure(lessonRegistrationsStructure)
                .getData(lessonRegistrationsEndpoint, {
                    groupID: groupID,
                    sessionID: auth.sessionID,
                    pageSize: pageSizeApiResponse,
                    byDate: endDate >= timeNow ? timeNow : endDate
                })
                .then((response) => {

                    if (response.payload.length === 0) {
                        throw "Something went wrong: getLessonDataByGroupAndDate error. No data."
                    }

                    setLessonRegistrations(response.payload || []);
                    setRegistrationsLoading(false);

                })
                .catch((e) => {
                    console.log(e);
                    setRegistrationsLoading(false);
                })
        } else {
            setErrorMessage("Group ID is not correct so it's not possible to get data. Please contact administrator.")
        }

    }, [groupID]);

    {/*

    useEffect( () => {
        // console.log(homeworkToggle);

        // handle toggle

    }, [homeworkToggle])
    */}

    const dateConverter = (unixTimestamp) => {
        const a = new Date(unixTimestamp);
        const months = ['January','February','March','April','May','June','Jule','August','September','October','November','December'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        // const hour = a.getHours();
        // const min = a.getMinutes();
        return date + ' ' + month + ' ' + year;
    }

    const timeConverter = (unixTimestamp) => {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute:'2-digit'
        });
    }

    return (
        <div className="pb-16">
            {!registrationsLoading?
                <>
                    {/*
                    <label htmlFor="academics-toggle" className="inline-flex relative items-center mt-5 mb-5 cursor-pointer">
                        <input type="checkbox" value={homeworkToggle} onChange={ () => setHomeworkToggle(!homeworkToggle)}
                               id="academics-toggle" className="sr-only peer"
                               checked={homeworkToggle}
                        />
                        <div
                            className="w-9 h-5 bg-slate-200 peer-focus:outline-none peer-focus:ring-4
                        peer-focus:ring-indigo-300 dark:peer-focus:ring-indigo-800 rounded-full peer
                        dark:bg-slate-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                        after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                        after:bg-white after:border-slate-300 after:border after:rounded-full
                        after:h-4 after:w-4 after:transition-all dark:border-slate-600 peer-checked:bg-indigo-600">
                        </div>
                        <span className="ml-3 text-sm font-base text-slate-900 dark:text-slate-300">
                            Show last {homeworkToShow} study days homework
                        </span>
                    </label>
                    */}

                    {/*
                    <p className="dark:text-slate-200">Client ID: {clientID}</p>
                    */}

                    {/*
                    <p className="dark:text-slate-400">ACADEMICS SECTION</p>
                    <p className="dark:text-slate-200">Group ID: {groupID}</p>

                    <p className="dark:text-slate-200 pt-4">Start Date: {startDate}</p>
                    <p className="dark:text-slate-200">End Date: {endDate}</p>
                    <p className="dark:text-slate-200">Time Now: {timeNow}</p>

                    <p className="dark:text-slate-200 text-xs py-8">
                        {calendarData.length}
                    </p>

                    */}

                    {calendarData.sort( (a, b) => b.date - a.date).map( (day, index) =>
                        <div className="flex flex-col p-5 mb-4 bg-slate-100 rounded-lg border border-slate-300
                        dark:bg-slate-800 dark:border-slate-700" key={index}>
                            <time className="text-base font-medium text-slate-900 dark:text-slate-50 pb-3">
                                {dateConverter(day.date)}
                            </time>
                            <div className="divide-y dark:divide-slate-700 divide-slate-200">
                                {day.bs_lessons.sort( (a, b) => a.date - b.date).map( (lesson) =>


                                    <div className="pl-4 py-4 first:pt-0 last:pb-0">
                                        <div className="flex flex-row gap-x-2 items-center">
                                            <p className="font-medium text-sm dark:text-slate-200 text-slate-800
                                             dark:text-slate-50">
                                                {timeConverter(lesson.starts_at) +
                                                    " – " +
                                                    timeConverter(lesson.starts_at + lesson.duration*60*1000)}
                                            </p>
                                            <p className="font-medium text-sm dark:text-indigo-500
                                            dark:text-indigo-400 text-indigo-600">{lesson.subject.subject}</p>
                                        </div>

                                        {lesson.registered
                                            ? <BsAcademicsLesson
                                                clientID={clientID}
                                                academicsData={
                                                    lessonRegistrations.filter(
                                                        registration => registration.lesson_id.id === lesson.id
                                                    )
                                                }
                                            />
                                            :
                                                <div className="flex flex-row items-center pt-3 text-red-500 dark:text-red-300 font-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                         strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                                    </svg>
                                                    <p className="text-xs pl-1.5">Lesson has not been registered yet.</p>
                                                </div>
                                        }

                                        {/*
                                        <p className="text-xs dark:text-white">{JSON.stringify(lesson)}</p>
                                        */}

                                    </div>

                                )}
                            </div>
                        </div>
                    )}

                    {/*
                    <p className="dark:text-slate-200 text-xs py-8">
                        {lessonRegistrations.length}
                    </p>
                    */}

                    {/*
                    {lessonRegistrations.map( (lesson) =>
                         <p className="dark:text-slate-200 text-xs py-2">
                             {JSON.stringify(lesson)}
                         </p>
                    )}
                    */}

                </>
                : <LoadingSpinner />
            }

        </div>


    );
}

export default BsAcademics;