import React from 'react';

function HomeCardToMakeRequest() {
    return (
        <>
            <div
                  className="block p-6 max-w-sm aspect-[16/9] shadow-md rounded-lg
               flex flex-row justify-center items-center align-middle transition duration-300
               bg-indigo-900 hover:bg-indigo-800 text-white
               dark:bg-yellow-500 dark:border-yellow-800 dark:hover:bg-yellow-400 text-center"
            >

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <h5 className="pl-2 text-md font-base tracking-normal text-white dark:text-white">
                    New request
                </h5>
            </div>
        </>
    );
}

export default HomeCardToMakeRequest;