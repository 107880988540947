import React from 'react';
import { NavLink } from "react-router-dom";
import LogoutButton from "../LogoutButton";
// import {ReactComponent as StudyLabLogo} from "../../studylab.svg";


function ClientSidebar() {
    return (
        <aside className="h-screen sticky top-0 w-80 border-r dark:border-r-slate-700" aria-label="Sidebar" >
            <div className="overflow-y-auto min-h-screen py-6 px-6 bg-slate-50 dark:bg-slate-900
            flex flex-col justify-between">
                <div>
                    <ul className="space-y-2">

                        {/* HOME */}
                        <li>
                            <NavLink to="/"
                                     className="group flex items-center p-2 pl-3 rounded-lg
                                     text-sm font-normal
                                     text-black hover:bg-slate-100
                                     dark:text-slate-300 dark:hover:text-white dark:hover:bg-slate-700">
                                <svg aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor"
                                     className="flex-shrink-0 w-4 h-4 text-black transition duration-300
                                     dark:text-slate-300 group-hover:text-black dark:group-hover:text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap transition duration-300">Home</span>
                            </NavLink>
                        </li>

                        {/* BRITISH SCHOOL */}
                        <li>
                            <NavLink to="/british"
                                     className={({ isActive }) => !isActive ? "group flex items-center p-2 pl-3 rounded-lg text-sm font-normal text-black hover:bg-slate-100 dark:text-slate-300 dark:hover:text-white dark:hover:bg-slate-700"
                                         : "group flex items-center p-2 pl-3 rounded-lg text-sm font-normal text-black bg-slate-100 dark:text-white dark:bg-slate-700"}>
                                <svg aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor"
                                     className="flex-shrink-0 w-4 h-4 text-black transition duration-300
                                     dark:text-slate-300 group-hover:text-black dark:group-hover:text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"/>
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap transition duration-300">British School</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div>
                    <LogoutButton />
                </div>

            </div>
        </aside>
    );
}

export default ClientSidebar;