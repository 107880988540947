import React from 'react';
import BsCalendarLesson from "./BsCalendarLesson";

function BsCalendarDay({dayData, trialDate }) {

    const dateConverter = (unixTimestamp) => {
        const a = new Date(unixTimestamp);
        const months = ['January','February','March','April','May','June','Jule','August','September','October','November','December'];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        // const hour = a.getHours();
        // const min = a.getMinutes();
        return date + ' ' + month + ' ' + year;
    }

    {/*
    const timeConverter = (unixTimestamp) => {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute:'2-digit'
        });
    }
    */}

    return (

        <div className="flex flex-col p-5 mb-4 bg-slate-100 rounded-lg border border-slate-300
                dark:bg-slate-800 dark:border-slate-700 w-full">
            <div className="flex flex-col lg:flex-row gap-y-1 pb-3 gap-x-4 lg:items-center">
                <div className="flex flex-row gap-x-4 items-center">
                    <time className="text-base font-medium text-slate-900 dark:text-slate-50">
                        {dateConverter(dayData.date)}
                    </time>
                    <time className="text-base font-base text-indigo-600 dark:text-indigo-400">
                        {dayData.date_name}
                    </time>
                </div>
                {/* TRIAL DAY TAG */}
                <div>
                    {dateConverter(dayData.date) === dateConverter(trialDate) &&
                        <p className="text-xs font-light">
                            <span className="font-normal px-3 py-0.5 rounded-full
                            text-slate-900 bg-yellow-400
                            dark:text-slate-900 dark:bg-yellow-400">TRIAL DAY</span>
                        </p>
                    }
                </div>
            </div>

            <div className="divide-y dark:divide-slate-700 divide-slate-200">
                {dayData.bs_lessons.sort( (a, b) => a.starts_at - b.starts_at).map( (lesson) =>
                    <BsCalendarLesson key={lesson.id} lesson={lesson} />
                )}
            </div>

        </div>


    );
}

export default BsCalendarDay;