import React, { useState, useEffect } from 'react';
import Directual from "directual-api";
import { useAuth } from "../../../context/AuthProvider";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import BsReport from "./BsReport";

const api = new Directual({apiHost: '/'});

function BsReports({clientID, bsYearID}) {

    const auth = useAuth();

    {/* SET UP API REQUEST */}
    const reportsStructure = "bs_reports";
    const reportsEndpoint = "getReportsByStudentAndYear";

    const [reportsLoading, setReportsLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {

        if (clientID.length === 36 && bsYearID.length === 36) {

            setReportsLoading(true);

            api
                .structure(reportsStructure)
                .getData(reportsEndpoint, {
                    studentID: clientID,
                    yearID: bsYearID,
                    sessionID: auth.sessionID
                })
                .then((response) => {

                    if (response.payload.length === 0) {
                        throw "Something went wrong: getReportsByStudentAndYear error. No data."
                    }

                    setReports(response.payload || []);
                    setReportsLoading(false);

                })
                .catch((e) => {
                    console.log(e);
                    setReportsLoading(false);
                })
        } else {
            setErrorMessage("groupID or yearID is not correct so it's not possible to get data. Please contact administrator.")
        }

    }, [clientID, bsYearID]);

    return (
        <div>
            {!reportsLoading ?
                <>
                    {reports.length === 0?
                        <>
                            <p className="p-4 text-sm text-slate-700 bg-slate-100 rounded-lg
                                dark:bg-slate-700 dark:text-slate-300">
                                This section contains <strong
                                className="font-medium text-gray-800 dark:text-white">the quarterly reports</strong> for the
                                academic year.
                            </p>
                        </>
                        :
                        <div className="flex flex-col gap-y-6 p-4 text-sm text-slate-700
                                dark:text-slate-300">
                            {reports.sort( (a, b) => b.report_date - a.report_date).map((report) =>
                                <BsReport
                                    reportData={report}
                                />
                            )}
                        </div>
                    }
                </>
                : <LoadingSpinner/>
            }
        </div>
    );
}

export default BsReports;