import React from 'react';

function BsAcademicsIndicators({ indicators }) {
    return (
        <>

            {/*
            <div>{JSON.stringify(indicators)}</div>
            */}

            {indicators.length > 0 &&

                indicators[0].isPresent
                ? (
                    <div className="flex flex-row flex-wrap gap-y-1 py-2 gap-x-2">

                        {/* ATTENDANCE BULLET */}
                        <div className="flex flex-row gap-x-1 items-center py-1 rounded-lg px-3 font-light text-xs
                        dark:bg-green-600 dark:text-slate-50
                        bg-green-300 text-slate-900">
                            <p className="font-base">Present</p>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        </div>

                        {/* HOMEWORK BULLET */}
                        {indicators[0].didHomework
                            ? (
                                <div className="flex flex-row gap-x-1 items-center rounded-lg py-1 px-3
                                dark:bg-green-600 dark:text-slate-50 font-light text-xs
                                bg-green-300 text-slate-900">
                                    <p className="font-base">Homework</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                         stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                </div>
                            )
                            : (
                                <div className="flex flex-row gap-x-1 items-center rounded-lg py-1 px-3
                                dark:bg-red-400 dark:text-slate-50 font-light text-xs
                                bg-red-200 text-slate-900">
                                    <p className="font-base">Homework</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                         stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                </div>
                            )
                        }

                        {/* BEHAVIOUR BULLET */}
                        {indicators[0].behavior === "good"
                            ? (
                                <div className="flex flex-row gap-x-1 items-center py-1 rounded-lg px-3
                                dark:bg-green-600 dark:text-slate-50 font-light text-xs
                                bg-green-300 text-slate-900">
                                    <p className="font-base">Behaviour</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5"
                                         stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                </div>
                            )
                            : (
                                <div className="flex flex-row gap-x-1 items-center py-1 rounded-lg px-3
                                dark:bg-red-400 dark:text-slate-50 font-light text-xs
                                bg-red-200 text-slate-900">
                                    <p className="font-base">Behaviour</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                                    </svg>

                                </div>
                            )
                        }

                        {/* AWARD BULLET */}
                        {indicators[0].awards.length > 0 &&
                            (
                                <div className="flex flex-row gap-x-1 items-center py-1 rounded-lg px-3
                                dark:bg-yellow-400 dark:text-yellow-900 font-light text-xs
                                bg-yellow-400 text-slate-900">
                                    {indicators[0].awards === "effort" &&
                                        <p className="font-normal">Great Effort</p>
                                    }
                                    {indicators[0].awards === "homework" &&
                                        <p className="font-normal">Homework Hero</p>
                                    }
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"/>
                                    </svg>
                                </div>
                            )
                        }
                    </div>
                )
                : (
                    <div className="flex flex-row py-2">
                        <div className="flex flex-row gap-x-1 items-center py-1 rounded-lg px-3
                        dark:bg-red-400 dark:text-slate-50 font-light text-xs
                        bg-red-200 text-slate-900">
                            <p className="font-base">Absent</p>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default BsAcademicsIndicators;