import Login from "./pages/auth/Login";
import Layout from "./components/Layout";
import ClientHome from "./pages/client/ClientHome";
import Missing from "./pages/missing/Missing";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route } from "react-router-dom"
import Unauthorized from "./pages/auth/Unauthorized";
import TeacherHome from "./pages/teacher/TeacherHome";
import Forgot from "./pages/auth/Forgot";

import ClientLayout from "./pages/client/ClientLayout";
import ClientBritishSchool from "./pages/client/ClientBritishSchool";
import BsTabs from "./pages/client/British School/BsTabs";
import BsLayout from "./pages/client/British School/BsLayout";

import TeacherLayout from "./pages/teacher/TeacherLayout";
import TeacherBritishSchool from "./pages/teacher/British School/TeacherBritishSchool";
import TeacherBsLayout from "./pages/teacher/British School/TeacherBsLayout";
import TeacherRegisterLesson from "./pages/teacher/British School/TeacherRegisterLesson";
import UpdateBsLesson from "./pages/teacher/British School/UpdateBsLesson";

function App() {
  return (
      <Routes>
          <Route path="/" element={<Layout />}>

              {/* PUBLIC ROUTES */}
              <Route path="login" element={<Login />} />
              <Route path="forgot" element={<Forgot />} />
              <Route path="unauthorized" element={<Unauthorized />} />

              {/* PROTECTED ROUTES */}
              {/* CLIENT */}
              <Route element={<RequireAuth allowedRole={'client'}/>}>
                  <Route path="/" element={ <ClientLayout /> }>
                      <Route index element={ <ClientHome /> }  />
                      <Route path="british" element={ <BsLayout /> }>
                          <Route index element={ <ClientBritishSchool /> } />
                          <Route path=":id" element={ <BsTabs /> } />
                      </Route>
                 </Route>
              </Route>

              {/* TEACHER */}
              <Route element={<RequireAuth allowedRole={'teacher'}/>}>
                  <Route path="/teacher" element={ <TeacherLayout /> }>
                      <Route index element={ <TeacherHome /> }  />
                      <Route path="british" element={ <TeacherBsLayout /> }>
                          <Route index element={ <TeacherBritishSchool /> } />
                          <Route path="register/:id" element={ <TeacherRegisterLesson />} />
                          <Route path="update/:id" element={ <UpdateBsLesson />} />
                      </Route>
                  </Route>
              </Route>

              {/* MISSING ROUTES */}
              <Route path="*" element={<Missing />} />

          </Route>
      </Routes>
  );
}

export default App;
