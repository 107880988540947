import { createContext, useState, useContext, useEffect } from "react";
import Directual from 'directual-api';
import { useAuth } from "./AuthProvider";

const api = new Directual({apiHost: '/'});

const DataContext = createContext({});

export const useData = () => {
    return useContext(DataContext);
};

export const DataProvider = ({ children }) => {

    const data = useProvideData();

    return (
        <DataContext.Provider value={ data }>
            {children}
        </DataContext.Provider>
    )
}

function useProvideData() {

    const dataStructure = 'WebUser' // Sysname of data structure
    const endpoint = 'getIds' // Method name of API-endpoint

    const clientDataStructure = "clients";
    const clientEndpoint = "getClientData";

    const auth = useAuth();

    const [loading, setLoading] = useState(false);
    const [clientID, setClientID] = useState('');
    const [teacherID, setTeacherID] = useState('');
    const [employeeID, setEmployeeID] = useState('');
    const [familyIDs, setFamilyIDs] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [clientData, setClientData] = useState([]);

    {/*
    const getClientData = (ids) => {

        console.log("getClientData RUUUUNS in DataProvider")

        const clientDataStructure = "clients";
        const clientEndpoint = "getClientData";

        if (ids.length !==0) {
            api
                .structure(clientDataStructure)
                .getData(clientEndpoint, {id: ids.join(","), sessionID: auth.sessionID})
                .then((response) => {
                    if (response.payload.length === 0) {
                        // console.log("Account doesn't exist.");
                        throw "Something went wrong: getClientData error. Missing important query parameters (user, session ID )."
                    }
                    const clientData = response.payload;
                })
                .catch((e) => {
                    console.log(e);
                })
        } else {
            console.log("API REQUEST FAIL: ids.length = 0")
        }
        return clientData;
    }
    */}

    /* API CALL :: GET ALL USER'S IDS */
    useEffect(() => {

        // console.log("Running useEffect in DataProvider - getIds");

        setLoading(true);
        // .getData(endpoint, {appID: APP_ID, id: auth.user, sessionID: auth.sessionID})
        if (auth.user) {

            // console.log("API CALL :: get all user's IDs")
            // console.log("auth.user: ", auth.user);
            // console.log("auth.sessionID: ", auth.sessionID);

            api
                .structure(dataStructure)
                .getData(endpoint, {id: auth.user, sessionID: auth.sessionID})
                .then((response) => {

                    // console.log("getIDS Response")
                    // console.log(response);

                    if (response.payload.length === 0) {
                        throw "Something went wrong: getIds error. " +
                        "Missing important query parameters: user or sessionID."
                    }

                    setClientID(response.payload[0].client_link || "");
                    setTeacherID(response.payload[0].teacher_link || "");
                    setEmployeeID(response.payload[0].employee_link || "");
                    setFamilyIDs(response.payload[0].family_link?.clients || []);

                    setLoading(false);

                })
                .catch((e) => {
                    // console.log("API CALL :: GET ALL USER'S IDS")
                    console.log(e);
                    setLoading(false);
                })
        } else {
            console.log("API REQUEST FAIL: auth.user doesn't exist");
            setLoading(false);
        }

    }, [auth.user]);

    /* API CALL :: GET CLIENT DATA USING IDS */
    useEffect(() => {

        // console.log("Running useEffect in DataProvider - getClientData");
        setLoading(true);

        if (familyIDs.length !==0) {

            // console.log("API CALL :: getClientData");

            api
                .structure(clientDataStructure)
                .getData(clientEndpoint, {id: familyIDs.join(","), sessionID: auth.sessionID})
                .then((response) => {

                    // console.log(response);

                    if (response.payload.length === 0) {
                        throw "Something went wrong: getClientData error. Missing important query parameters: IDs or sessionID."
                    }

                    setClientData(response.payload);
                    setLoading(false);

                })
                .catch((e) => {
                    // console.log("API CALL :: GET CLIENT DATA USING IDS");
                    console.log(e);
                    setLoading(false);
                })
        }
    }, [familyIDs]);

    return {
        loading,
        clientID,
        teacherID,
        employeeID,
        familyIDs,
        clientData
    };

}