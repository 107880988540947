import React from 'react';
import BsAcademicsIndicators from "./BsAcademicsIndicators";
import linkifyHtml from "linkify-html";
import parse from 'html-react-parser';


/*
* Задаем параметры как библиотека linkifyHtml будет видоизменять ссылки в тексте
* Подробнее: https://linkify.js.org/docs/
* */
const linkify_options = {
    defaultProtocol: 'https',
    target: '_blank',
    className: 'underline hover:no-underline dark:text-slate-400 text-slate-500',
    format: (value, type) => {
        if (type === 'url' && value.length > 40) {
            value = 'view link';
        }
        return value;
    },
};


function BsAcademicsLesson({ clientID, academicsData }) {

    return (

        <div className="text-xs text-yellow-400">
            {academicsData &&
                <>

                    {academicsData[0] &&
                        <BsAcademicsIndicators indicators={
                            JSON.parse(academicsData[0]?.academics_data).filter(
                                student => student.studentID === clientID
                            )
                        } />
                    }

                    <p className="pt-2 pb-1 font-light text-sm text-slate-800 dark:text-slate-50">
                        <strong className="dark:text-indigo-400
                        text-indigo-600 font-medium">Covered topics:</strong> {
                        academicsData[0]?.topics.length > 0? parse(linkifyHtml(academicsData[0]?.topics, linkify_options)) : ''
                    }
                    </p>

                    {/* CLASSWORK FILES */}
                    {academicsData[0]?.classwork_files.length > 10 &&
                        <div className="flex flex-row flex-wrap gap-x-2 gap-y-1 pb-2">
                            {JSON.parse(academicsData[0]?.classwork_files)?.length > 0
                                ? JSON.parse(academicsData[0]?.classwork_files)?.map( (file, index) =>
                                    (
                                        <div key={index} className="flex flex-row gap-x-2 items-center py-0.5 rounded-lg px-2
                                        dark:bg-slate-700 dark:text-slate-400
                                        bg-slate-200 text-slate-400
                                        ">

                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                                 className="w-3 h-3">
                                                <path fillRule="evenodd"
                                                      d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                                      clipRule="evenodd"/>
                                            </svg>

                                            <a href={file.urlLink} rel="noreferrer" className="underline"
                                               target="_blank">
                                                {file.originalFileName}
                                            </a>
                                        </div>
                                    ))
                                : <div></div>
                            }
                        </div>
                    }

                    {/*
                    <p className="">
                        <strong>Classwork files:</strong> {academicsData[0]?.classwork_files}
                    </p>
                    */}

                    <p className="pt-2 pb-1 font-light text-sm text-slate-800 dark:text-slate-50">
                        <strong className="dark:text-indigo-400
                        text-indigo-600 font-medium">Next lesson homework:</strong> {
                        academicsData[0]?.homework.length > 0? parse(linkifyHtml(academicsData[0]?.homework, linkify_options)) : ''
                    }
                    </p>


                    {/* HOMEWORK FILES */}
                    {academicsData[0]?.homework_files.length > 10 &&
                        <div className="flex flex-row flex-wrap gap-x-2 gap-y-1 pb-2">
                            {JSON.parse(academicsData[0]?.homework_files)?.length > 0
                                ? JSON.parse(academicsData[0]?.homework_files)?.map((file, index) =>
                                    (
                                        <div key={index} className="flex flex-row gap-x-2 items-center py-0.5 rounded-lg px-2
                                    dark:bg-slate-700 dark:text-slate-400
                                    bg-slate-200 text-slate-400">

                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                 fill="currentColor"
                                                 className="w-3 h-3">
                                                <path fillRule="evenodd"
                                                      d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                                      clipRule="evenodd"/>
                                            </svg>

                                            <a href={file.urlLink} rel="noreferrer" className="underline"
                                               target="_blank">
                                                {file.originalFileName}
                                            </a>
                                        </div>
                                    ))
                                : <div></div>
                            }
                        </div>
                    }
                    {/*
                    <p className="">
                        <strong>Homework files:</strong> {academicsData[0]?.homework_files}
                    </p>
                    */}
                </>
            }
        </div>

);
}

export default BsAcademicsLesson;