import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import App from './App';
import { AuthProvider } from "./context/AuthProvider";
import { DataProvider } from "./context/DataProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <AuthProvider>
              <DataProvider>
                  <Routes>
                      <Route path="/*" element={<App />} />
                  </Routes>
              </DataProvider>
          </AuthProvider>
      </BrowserRouter>
  </React.StrictMode>
);
