import React, {useEffect, useState} from 'react';
import { useData } from "../../context/DataProvider";
import { useAuth } from "../../context/AuthProvider";
import Directual from "directual-api";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

const api = new Directual({apiHost: '/'});

function TeacherHome() {

    const data = useData();
    const auth = useAuth();

    // console.log("DATA PROVIDER");
    // console.log(data);

    // console.log("AUTH PROVIDER");
    // console.log(auth);

    {/* SET UP API REQUEST*/}
    const teacherDataStructure = "teachers";
    const teacherEndpoint = "getTeacherData";

    const [loading, setLoading] = useState(false);
    const [teacherData, setTeacherData] = useState();
    const [teacherDataBadRequest, setTeacherDataBadRequest] = useState();

    useEffect( () => {
        // console.log("FETCH TEACHER DATA");
        setLoading(true);
        api
            .structure(teacherDataStructure)
            .getData(teacherEndpoint, {
                teacherID: data.teacherID,
                sessionID: auth.sessionID
            })
            .then((response) => {
                // console.log(response);
                if (response.payload.length === 0) {
                    throw "Something went wrong: getTeacherData error. Teacher ID (teacherID) is not correct."
                }
                setTeacherData(response.payload || []);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setTeacherDataBadRequest(e.msg);
                setLoading(false);
            })
    }, [data.teacherID])

    return (
        <div>
            {loading
                ? <LoadingSpinner />
                :
                (
                    <div>
                        {teacherData &&
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-slate-900
                            md:text-2xl dark:text-slate-50">
                                Welcome, {teacherData[0].first_name}
                            </h1>
                        }
                    </div>
                )
            }
        </div>
    );
}

export default TeacherHome;