import React, {useEffect, useState, useMemo} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Directual from "directual-api";
import { useAuth } from "../../../context/AuthProvider";
import { useData } from "../../../context/DataProvider";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { useForm, useFieldArray } from "react-hook-form";
import FileUpload from "../../../components/ui/FileUpload";
import LineSeparator from "../../../components/ui/LineSeparator";
import AppHelpers from "../../../helpers";
import InformationBlock from "../../../components/ui/InformationBlock";

const api = new Directual({apiHost: '/'});

function UpdateBsLesson() {

    const { id } = useParams();
    const navigate = useNavigate();

    const auth = useAuth();
    const webUserData = useData();

    /**
     * Получаем lessonID из ссылки: update/{...lessonID...}
     * lessonID потребуется для выполнения API запроса на получение данных об уроке
     * */
    const lessonID = id;

    /**
     * Параметры API-запроса для получения данных о зарегистрированном уроке: структура + название эндпойта
     * Настройка API производится в панели администратора Directual
     * */
    const registrationDataStructure = "bs_registrations";
    const registrationEndpoint = "getBsLessonRegistrationData";

    const [loading, setLoading] = useState(false);
    const [registration, setRegistration] = useState([]);

    const [errMsg, setErrMsg] = useState('');

    /**
     * Делаем API-запрос для получения данных о регистрации урока
     * */
    useEffect( () => {

        // Проверяем, что полученный lessonID из ссылки валиден по количеству символов
        if (lessonID.length === 36) {
            setLoading(true);
            api
                .structure(registrationDataStructure)
                .getData(registrationEndpoint, {
                    lessonID: lessonID,
                    teacherID: webUserData.teacherID,
                    sessionID: auth.sessionID
                })
                .then((response) => {
                    if (response.payload.length === 0) {
                        throw "#863456: getBsLessonRegistrationData error. Lesson ID is not correct or there is no registration to update."
                    }
                    setRegistration(response.payload || []);
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                })
        } else {
            setErrMsg("Lesson ID is not correct so it's not possible to get lesson registration data. Please contact administrator.")
        }
    }, [lessonID]);

    /**
     * Нормализуем данные о регистрации после получения данных по API-запросу
     * Выделяем из объекта данные с академическимим показателями: ученики, отметки о посещаемости,
     * статусы выполнения домашней работы и отметки о достижениях
     * Результаты нормализации записываем в State
     */
    const [academicsData, setAcademicsData] = useState([]);
    useMemo(() => {
        // console.log('Registration useMemo runs');
        if(registration.length !== 0) {
            setAcademicsData(JSON.parse(registration[0].academics_data))
        }
    }, [registration]);


    /**
     * После получения данных по API-запросу вытягиваем данные по индентификатору группы и регистрации в базе данных
     * Они потребуются для выполнения POST-запроса на сохранение данных
     */
    const [groupID, setGroupID] = useState('');
    const [registrationID, setRegistrationID] = useState('')
    useMemo(() => {
        if(registration.length !== 0) {
            setGroupID(registration[0].group_id.id);
            setRegistrationID(registration[0].id);
        }
    }, [registration]);


    /**
     * Настраиваем поля и значения по умолчанию формы обновления регистрации
     * Используется библиотека React Hook Form: https://react-hook-form.com/
     * */
    const { register, setValue, handleSubmit, control, formState: { errors } } = useForm( {
        defaultValues: {
            groupID: groupID,
            lessonID: lessonID,
            topics: "",
            homework: "",
            lessonPlanFilesLinks: "",
            classworkFilesLinks: "",
            homeworkFilesLinks: "",
            studentsData: []
        }
    });

    const { fields } = useFieldArray({
        control,
        name: "studentsData"
    });


    /**
     * Создаем массив с исходными данными по ученикам для поля fieldArray
     * Данные: ID ученика, полное имя ученика (в формате FNAME + LNAME), отметка о посещении,
     * отметка о статусе домашней работы, отметка о поведении, достижения
     */
    const [studentsDefaultValues, setStudentsDefaultValues] = useState([]);

    useMemo( () => {
        // console.log('Set default values useMemo runs');
        if (academicsData.length !== 0) {
            setStudentsDefaultValues(academicsData.map( (student) => {
                    return {
                        studentID: student.studentID || '',
                        student: student.student || '',
                        isPresent: student.isPresent,
                        didHomework: student.didHomework,
                        behavior: student.behavior || "good",
                        awards: student.awards || ""
                    }
                }
            ))
        }
    }, [academicsData]);


    /**
     * Присваиваем исходные значения по ученикам в хранилище React Hook Form
     * Поле studentsData
     */
    useMemo( () => {
        // console.log("Setting default values for field array: ", JSON.stringify(studentsDefaultValues));
        setValue("studentsData", studentsDefaultValues);
    }, [studentsDefaultValues]);


    /**
     * Присваиваем исходные значения для текстовых полей (textarea) с классной и домашней работой в хранилище React Hook Form
     * Поля "topics" и "homework"
     */
    useMemo( () => {
        // console.log("Setting default values for field array: ", JSON.stringify(studentsDefaultValues));
        setValue("topics", registration[0]?.topics || '');
    }, [registration]);

    useMemo( () => {
        // console.log("Setting default values for field array: ", JSON.stringify(studentsDefaultValues));
        setValue("homework", registration[0]?.homework || '');
    }, [registration]);


    /**
     *  State для работ с файлами
     *  В первом State будут храниться данные о загружаемых файлах
     *  Во втором (original...) хранится информация о ранее загруженных файлах (необходимо только для обновления уроков)
     */
    const [lessonPlanFiles, setLessonPlanFiles] = useState([]);
    const [originalLessonPlanFiles, setOriginalLessonPlanFiles] = useState('');

    const [classworkFiles, setClassworkFiles] = useState([]);
    const [originalClassworkFiles, setOriginalClassworkFiles] = useState('');

    const [homeworkFiles, setHomeworkFiles] = useState([]);
    const [originalHomeworkFiles, setOriginalHomeworkFiles] = useState('');

    /**
     * Сохраняем в State данные по ранее загруженным файлам
     * В базе данных эти записи хранятся в формате string
     */
    useMemo( () => {
        // console.log("useMemo for files runs")
        if(registration.length !== 0) {
            setOriginalLessonPlanFiles(registration[0].lesson_plan_file);
            setOriginalClassworkFiles(registration[0].classwork_files);
            setOriginalHomeworkFiles(registration[0].homework_files);
        }
    }, [registration]);


    /**
     * Заносим все данные по файлам в поля React Hook Form
     * */
    useMemo( () => {
        setValue("classworkFilesLinks", JSON.stringify(classworkFiles));
    }, [classworkFiles])

    useMemo( () => {
        setValue("lessonPlanFilesLinks", JSON.stringify(lessonPlanFiles));
    }, [lessonPlanFiles])

    // Создаем JSON-строку с данными о файлах для домашней работы
    useMemo( () => {
        setValue("homeworkFilesLinks", JSON.stringify(homeworkFiles));
    }, [homeworkFiles])


    /**
     * Настраиваем State для POST запроса на редактирование данных о регистрации
     * */
    const [posting, setPosting] = useState(false);
    const [postDataBadRequest, setPostDataBadRequest] = useState({});

    /**
     * Параметры API-запроса для обновления данных о зарегистрированном уроке: структура + название эндпойта
     * Настройка API производится в панели администратора Directual
     * */
    const lessonRegistrationDataStructure = "bs_registrations";
    const lessonRegistrationEndpoint = "postRegistration";

    /**
     * Отправляем данные о регистрации в Directual
     * */
    const onSubmit = (data) => {

        // console.log("onSubmit Event");
        // console.log(data);

        const presentStudents = data.studentsData.filter( student =>
            student.isPresent === true
        );

        const presentStudentIDs = presentStudents.map( student => {
            return student.studentID
        });

        /**
         * Сохраняем данные об уроке в константу для отправки
         * */
        const sendingData = {
            id: registrationID,
            lesson_id: lessonID,
            group_id: groupID,
            present_students: presentStudentIDs.join(","),
            topics: data.topics,
            homework: data.homework,
            academics_data: JSON.stringify(data.studentsData),
            teacher: webUserData.teacherID,
            lesson_plan_file: data.lessonPlanFilesLinks,
            classwork_files: data.classworkFilesLinks,
            homework_files: data.homeworkFilesLinks
        };

        // console.log("Sending data:");
        // console.log(sendingData);

        setPosting(true);

        api
            .structure(lessonRegistrationDataStructure)
            .setData(lessonRegistrationEndpoint, sendingData,
                {
                    sessionID: auth.sessionID
                })
            .then((response) => {
                // console.log(response);
                setPosting(false);
                navigate("/teacher/british");
            })
            .catch((error) => {
                // handling errors
                setPosting(false);
                console.log(error);
                setPostDataBadRequest({
                    httpCode: error.httpCode || 'Directual Error' ,
                    msg: error.msg || 'Server doesn\'t respond or request is not correct.'
                })
            })
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="xl:px-20 pb-12">
                {(!loading)?

                    <>
                        <div className="flex flex-row justify-between items-end mt-8 mb-4">
                            <h1 className="text-3xl dark:text-slate-200 text-slate-800 font-bold">
                                Update Lesson Registration
                            </h1>
                            <button type="button" onClick={() => {navigate("/teacher/british")}}
                                    className="text-red-500 border border-red-500 hover:border-red-600 hover:bg-red-600
                                    hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300
                                    font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center
                                    dark:border-red-500 dark:text-red-500
                                    dark:hover:text-white dark:focus:ring-red-800">
                                Discard Changes
                            </button>
                        </div>


                        {registration.length !== 0 ?
                            <>
                                <h2 className={"text-lg dark:text-indigo-300 font-bold text-indigo-800 pt-2"}>
                                    {registration[0]?.group_id?.full_name || ''}
                                </h2>

                                {/*
                                    Данные об уроке:
                                    Дата урока, время начала и окончания, предмет
                                */}
                                <div className="flex flex-row gap-x-4 pb-6">
                                    <p className="dark:text-indigo-300 text-indigo-800 text-base">
                                        {AppHelpers.dateConverter(registration[0]?.lesson_id?.starts_at) || ''}
                                    </p>
                                    <p className="dark:text-indigo-300 text-indigo-800 text-base">
                                        {AppHelpers.timeConverter(registration[0]?.lesson_id?.starts_at)}&nbsp;–&nbsp;
                                        {AppHelpers.timeConverter(registration[0]?.lesson_id?.starts_at + registration[0]?.lesson_id?.duration * 60 * 1000)}
                                    </p>
                                    <p className="dark:text-indigo-300 text-indigo-800 text-base">
                                        {registration[0]?.lesson_id?.subject?.subject || ''}
                                    </p>
                                </div>

                                {/*
                                    Системная информация об уроке:
                                    lessonID, groupID, registrationID
                                */}
                                <div className="pl-4 pb-8">
                                    <p className="dark:text-slate-400 text-slate-500 text-xs py-0.5">
                                        <strong>Lesson ID:</strong> {lessonID}
                                    </p>
                                    <p className="dark:text-slate-400 text-slate-500 text-xs py-0.5">
                                        <strong>Group ID:</strong> {registration[0]?.group_id?.id || ''}
                                    </p>
                                    {registration.length === 1 &&
                                        <p className="dark:text-slate-400 text-slate-500 text-xs py-0.5">
                                            <strong>Registration ID:</strong> {registration[0]?.id || ''}
                                        </p>
                                    }
                                </div>

                                {/*
                                    Проверяем количество регистраций на данный урок в ответе API
                                    Если количество регистраций больше одной, значит в системе есть дубли и обновить данные
                                    нет возможности. Выводим преподавателю сообщение, что есть дубли, и необходимо
                                    обратиться к администратору для устранения дубликата.
                                */}
                                {registration.length === 1
                                    ?
                                    <>
                                        <LineSeparator text={"Lesson Planning"}/>

                                        <div className="">

                                            {/* LESSON PLAN FILE UPLOAD */}
                                            <div className="py-6">
                                                <h3 className="pb-1 text-sm font-base text-slate-800 dark:text-slate-400">
                                                    Upload lesson plan
                                                </h3>
                                                {/*
                                                 originalFiles можно передать данные о ранее загруженных файлах, формат sting || object || array
                                                    Если файлов нет, необходимо указать либо:
                                                        пустую строку ""
                                                        пустой массив []
                                                        пустой объект {}

                                                 isMultiple определяет режим работы input для файлов:
                                                    isMultiple = true - возможна загрузка нескольких файлов
                                                    isMultiple = false - возможна загрузка только одного файла

                                                 maxFileSize определяет максимально допустимый размер файла для загрузки

                                                 maxNumberOfFiles определяет максимально допустимое количество файлов
                                                    Если поле isMultiple === false, допустимо maxNumberOfFiles указать равным 1 или 0

                                                 session - значение сессии пользователя для Security Level API
                                                 */}
                                                <FileUpload
                                                    originalFiles={originalLessonPlanFiles}
                                                    isMultiple={false}
                                                    maxFileSize={2 * 1024 * 1024}
                                                    maxNumberOfFiles={1}
                                                    session={auth.sessionID}
                                                    setResultFiles={setLessonPlanFiles}
                                                />
                                            </div>


                                            <LineSeparator text={"Academic Performance"}/>


                                            {/* HEADERS FOR STUDENT ARRAY FIELDS */}
                                            <div className="pt-6 pb-8">
                                                <div className="mb-1.5 grid grid-cols-5 gap-4
                                                    text-xs font-normal text-slate-200 dark:text-slate-400
                                                    bg-sky-950 dark:bg-sky-950 rounded-md">
                                                    <div className="py-2 pl-4 text-left">
                                                        Student
                                                    </div>
                                                    <div className="py-2 text-center">
                                                        Attendance
                                                    </div>
                                                    <div className="py-2 text-center">
                                                        Homework
                                                    </div>
                                                    <div className="py-2 text-left pl-4">
                                                        Behaviour
                                                    </div>
                                                    <div className="py-2 text-left pl-4">
                                                        Awards
                                                    </div>
                                                </div>

                                                {/* ARRAY FIELDS WITH ACADEMIC DATA */}
                                                {fields.map((field, index) => (
                                                    <div key={field.id}
                                                         className="flex row py-1 rounded-lg grid grid-cols-5 gap-4 items-center">

                                                        <p className="text-sm pl-4 dark:text-slate-200 text-slate-800 py-0.5 truncate">
                                                            {field.student}
                                                        </p>

                                                        <div className="flex justify-center">
                                                            <input
                                                                type="checkbox" {...register(`studentsData.${index}.isPresent`)}
                                                                className="w-4 h-4 text-indigo-600 bg-slate-100 rounded border-slate-300
                                                                    focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-slate-800
                                                                    focus:ring-2 dark:bg-slate-700 dark:border-slate-700"/>
                                                        </div>

                                                        <div className="flex justify-center">
                                                            <input
                                                                type="checkbox" {...register(`studentsData.${index}.didHomework`)}
                                                                className="w-4 h-4 text-indigo-600 bg-slate-100 rounded border-slate-300
                                                                    focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-slate-800
                                                                    focus:ring-2 dark:bg-slate-700 dark:border-slate-700"/>
                                                        </div>

                                                        <div className="pl-4">
                                                            <select className="bg-slate-50 border border-slate-300 text-slate-900
                                                                    text-xs rounded-md focus:ring-indigo-500 focus:border-indigo-500 block
                                                                    w-full px-2.5 py-1 dark:bg-slate-800 dark:border-slate-700
                                                                    dark:placeholder-slate-400 dark:text-slate-200 dark:focus:ring-indigo-500
                                                                    dark:focus:border-indigo-500"
                                                                    defaultValue={"good"}
                                                                    {...register(`studentsData.${index}.behavior`)}>
                                                                <option value={"good"}>
                                                                    Good
                                                                </option>
                                                                <option value={"issue"}>
                                                                    Disruptive
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div className="px-4">
                                                            <select className="bg-slate-50 border border-slate-300 text-slate-900
                                                                    text-xs rounded-md focus:ring-indigo-500 focus:border-indigo-500 block
                                                                    w-full px-2.5 py-1 dark:bg-slate-800 dark:border-slate-700
                                                                    dark:placeholder-slate-400 dark:text-slate-200 dark:focus:ring-indigo-500
                                                                    dark:focus:border-indigo-500"
                                                                    defaultValue={""}
                                                                    {...register(`studentsData.${index}.awards`)}>
                                                                <option value={""}>
                                                                </option>
                                                                <option value={"effort"}>
                                                                    Great Effort
                                                                </option>
                                                                <option value={"homework"}>
                                                                    Homework Hero
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>


                                            <LineSeparator text={"Classwork"}/>


                                            {/* COVERED TOPICS FIELDS*/}
                                            <div className="pt-4 pb-8">
                                                <label htmlFor="topics" className="mb-1 text-sm font-base
                                                            text-slate-800 dark:text-slate-400">
                                                    Covered topics *
                                                </label>
                                                <textarea {...register("topics", {required: true})}
                                                          id="topics" rows="3"
                                                          className="block p-2.5 w-full text-sm text-slate-800 bg-slate-50 rounded-lg
                                                                      border border-slate-300
                                                                      focus:ring-indigo-500 focus:border-indigo-500
                                                                      dark:bg-slate-800 dark:border-slate-700 dark:placeholder-slate-400
                                                                      dark:text-slate-200 dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                          placeholder="Topics ...">
                                                </textarea>
                                                {errors.topics &&
                                                    <p className="text-xs pt-0.5 font-base text-red-500">
                                                        Sorry, topics are required
                                                    </p>}


                                                {/* CLASSWORK FILES UPLOAD */}
                                                <h3 className="pt-6 mb-1 text-sm font-base text-slate-800 dark:text-slate-400">
                                                    Upload classwork files
                                                </h3>
                                                {/*
                                                 originalFiles можно передать данные о ранее загруженных файлах, формат sting || object || array
                                                    Если файлов нет, необходимо указать либо:
                                                        пустую строку ""
                                                        пустой массив []
                                                        пустой объект {}

                                                 isMultiple определяет режим работы input для файлов:
                                                    isMultiple = true - возможна загрузка нескольких файлов
                                                    isMultiple = false - возможна загрузка только одного файла

                                                 maxFileSize определяет максимально допустимый размер файла для загрузки

                                                 maxNumberOfFiles определяет максимально допустимое количество файлов
                                                    Если поле isMultiple === false, допустимо maxNumberOfFiles указать равным 1 или 0

                                                 session - значение сессии пользователя для Security Level API
                                                 */}
                                                <FileUpload
                                                    originalFiles={originalClassworkFiles}
                                                    isMultiple={true}
                                                    maxFileSize={2 * 1024 * 1024}
                                                    maxNumberOfFiles={5}
                                                    session={auth.sessionID}
                                                    setResultFiles={setClassworkFiles}
                                                />
                                            </div>


                                            <LineSeparator text={"Homework"}/>


                                            {/* HOMEWORK FIELDS */}
                                            <div className="pt-4 pb-8">
                                                <label htmlFor="homework"
                                                       className="mb-1 text-sm font-base text-slate-800 dark:text-slate-400">
                                                    Next lesson homework *
                                                </label>
                                                <textarea {...register("homework", {required: true})}
                                                          id="homework" rows="3"
                                                          className="block p-2.5 w-full text-sm text-slate-800 bg-slate-50 rounded-lg
                                                                      border border-slate-300
                                                                      focus:ring-indigo-500 focus:border-indigo-500
                                                                      dark:bg-slate-800 dark:border-slate-700 dark:placeholder-slate-400
                                                                      dark:text-slate-200 dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                                          placeholder="Homework ...">
                                                </textarea>
                                                {errors.homework &&
                                                    <p className="text-xs pt-0.5 font-base text-red-500">
                                                        If you don't have homework for next lesson, write "No homework"
                                                    </p>}

                                                {/* HOMEWORK FILES UPLOAD */}
                                                <h3 className="pt-6 mb-1 text-sm font-base text-slate-800 dark:text-slate-400">
                                                    Upload homework files
                                                </h3>
                                                {/*
                                                 originalFiles можно передать данные о ранее загруженных файлах, формат sting || object || array
                                                    Если файлов нет, необходимо указать либо:
                                                        пустую строку ""
                                                        пустой массив []
                                                        пустой объект {}

                                                 isMultiple определяет режим работы input для файлов:
                                                    isMultiple = true - возможна загрузка нескольких файлов
                                                    isMultiple = false - возможна загрузка только одного файла

                                                 maxFileSize определяет максимально допустимый размер файла для загрузки

                                                 maxNumberOfFiles определяет максимально допустимое количество файлов
                                                    Если поле isMultiple === false, допустимо maxNumberOfFiles указать равным 1 или 0

                                                 session - значение сессии пользователя для Security Level API
                                                 */}
                                                <FileUpload
                                                    originalFiles={originalHomeworkFiles}
                                                    isMultiple={true}
                                                    maxFileSize={2 * 1024 * 1024}
                                                    maxNumberOfFiles={5}
                                                    session={auth.sessionID}
                                                    setResultFiles={setHomeworkFiles}
                                                />
                                            </div>


                                            {!posting ?
                                                <button className="mt-8 text-white bg-indigo-800 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300
                                                                font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-indigo-800 dark:hover:bg-indigo-700
                                                                focus:outline-none dark:focus:ring-indigo-800">
                                                    Update
                                                </button>
                                                :
                                                <div role="status" className="mt-8 flex flex-row items-center gap-x-1">
                                                    <svg aria-hidden="true"
                                                         className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600"
                                                         viewBox="0 0 100 101" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor"/>
                                                        <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill"/>
                                                    </svg>
                                                    <span
                                                        className="text-indigo-600 font-base text-sm">Loading...</span>
                                                </div>
                                            }
                                        </div>
                                    </>
                                    : <InformationBlock
                                        message={["This lesson has more than one registration, so\u00A0there is\u00A0no\u00A0way to\u00A0update the data. This is\u00A0a\u00A0bug that needs to\u00A0be\u00A0fixed. Please contact the\u00A0administrator [",
                                            <a href="mailto:curator@studylab.ru" className="underline text-indigo-300">curator@studylab.ru</a>,
                                            `] with the lesson\u00A0ID: ${lessonID}.`]}
                                        optionClasses={""}
                                    />
                                }
                            </>
                            : errMsg.length > 0
                                ? <InformationBlock message={errMsg} optionClasses={"mt-12"} />
                                : <InformationBlock message={"This lesson doesn't exist or you don't have required permissions. Please contact administrator."} optionClasses={"mt-12"} />
                        }
                    </>
                    : <LoadingSpinner />
                }
                {postDataBadRequest.httpCode &&
                    <InformationBlock message={`An error occurred while updating the lesson. Please try again later or contact the administrator. Error code: ${postDataBadRequest.httpCode}. Error message: ${postDataBadRequest.msg}` } optionClasses={"mt-6"} />
                }
            </form>
            {/*
            <p className="text-white text-7xl">HELLO</p>
            */}

            {/* postDataBadRequest */}

        </>
    );
}

export default UpdateBsLesson;