import React from 'react';

function BsCalendarLesson(lesson) {

    const timeConverter = (unixTimestamp) => {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute:'2-digit'
        });
    }

    return (
        <div className="pl-4 py-3 first:pt-0 last:pb-0">
            <p>
                <strong className="font-medium text-sm text-slate-800 dark:text-slate-50">
                    {timeConverter(lesson.lesson.starts_at) + " – " + timeConverter(lesson.lesson.starts_at + lesson.lesson.duration*60*1000)}
                </strong>
            </p>
            <p className="text-sm dark:text-slate-400 text-slate-400">{lesson.lesson.subject.subject + " – " + lesson.lesson.teacher.first_name + " " + lesson.lesson.teacher.last_name}</p>
            <p className="text-sm dark:text-slate-400 text-slate-400">{lesson.lesson.location.location}</p>
        </div>
    );
}

export default BsCalendarLesson;