import React from 'react';
import { useData } from "../../context/DataProvider";
import BsYearCard from "./British School/BsYearCard";
import HomeActiveServiceBsCard from "./Home/HomeActiveServiceBsCard";


function ClientBritishSchool() {

    // console.log("Client British School renders")
    const data = useData();

    const bsData = data.clientData.filter((client) => {
        if (client.british_school_experinece) {
            return client
        }
    })

    return (

        <>
            <h1 className="text-xl font-normal text-slate-400
                            md:text-2xl dark:text-slate-300 pt-1 pb-2">
                British School StudyLab
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3
                gap-6 pt-4">

                {bsData.map( (client, index) =>
                    client.british_school_experinece.map( (year, index) =>
                        year.bs_year.is_active &&
                        <HomeActiveServiceBsCard key={index}
                                                 academicName={year.bs_year.academic_name}
                                                 academicYear={year.bs_year.academic_year}
                                                 clientId={client.id}
                                                 groupId={year.id}
                                                 student={client.full_name}
                        />
                    )
                )}

            </div>

        </>

    );
}

export default ClientBritishSchool;