import React, {useState, useMemo, useEffect, useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import Directual from "directual-api";

const api = new Directual({apiHost: '/'});

function FileUpload({originalFiles, isMultiple, maxFileSize, maxNumberOfFiles, session, setResultFiles}) {

    /**
     * Параметры API-запроса для структуры File Storage в Directual
     */
    const fileStorage = "FileUpload";
    const fileEndpoint = "uploadFiles";

    const [initialData, setInitialData] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [maxNumberOfFilesError, setMaxNumberOfFilesError] = useState('');

    const [loadingFile, setLoadingFile] = useState(false);
    const [fileStorageResponse, setFileStorageResponse] = useState();
    const [fileStorageStatus, setFileStorageStatus] = useState();
    const [fileStorageBadRequest, setFileStorageBadRequest] = useState();

    /**
     * Нормализация данных о ранее загруженных файлах, если они были
     * Необходимый формат: массив объектов
     * */
    useEffect( () => {

        // console.log("useEffect Runs")

        /**
         * Нормализуем исходные данные о загруженных файлах
         * В конечном счете важно, чтобы данные хранились как массив объектов: [{...}, {...}, ...]
         * На вход данные могут прийти в следующих схемах:
         * string: "..." - в таком типе данные хранятся в базе данных
         * object: {...} - объект с данными об одном файле (по ошибке на ранних этапах не оборачивали в массив)
         * array: [{...}, {...}, ...] - данные о нескольких файлах
         * */

        /**
         * На входе массив.
         * Сохраняем массив в State как есть. Если массив не содержит элементов
         * ничего не делаем, т.к. в исходным значением в useState указан пустой массив
         * */
        if (Array.isArray(originalFiles) === true) {
            if(originalFiles.length > 0) {
                setInitialData(JSON.parse(JSON.stringify(originalFiles)));
            }
            return;
        }

        /**
         * На входе объект, что в JS может быть как массивом, так и объектом
         * Проверяем, что объект не является массивом, и сохраняем его в State,
         * оборачивая в массив
         * */
        if (typeof originalFiles === "object") {
            if (Array.isArray(originalFiles) === false) {
                setInitialData([JSON.parse(JSON.stringify(originalFiles))]);
            }
            return;
        }

        /**
         * На входе строка
         * Проверяем, что строка не является пустой, и парсим из неё JSON
         * Если строка пустая, ничего не делаем.
         * После JSON.parse стандратная логика: массив || объект
         * */
        if (typeof originalFiles === "string") {
            if(originalFiles.length > 0) {
                try {
                    const parsedData = JSON.parse(originalFiles);
                    if (Array.isArray(parsedData) === true) {
                        if(parsedData.length > 0) {
                            setInitialData(parsedData);
                        }
                        return;
                    }
                    if (typeof parsedData === "object") {
                        if (Array.isArray(parsedData) === false) {

                            /**
                             * Дополнительная проверка, что у объекта (у ключей) есть значения.
                             * Ранее в lesson_plan_file, даже если файл не был загружен, хранился пустой объект вида:
                             * {originalFileName : "", urlLink : ""}
                             * Данной проверкой считаем их пустыми и не заносим в State исходных данных
                             * */
                            if(parsedData.originalFileName.length > 0) {
                                setInitialData([parsedData]);
                            }
                        }
                    }
                } catch(e) {
                    // Ошибка при парсинге входных данных.
                    // Оставляем State как есть
                    setInitialData([]);
                }
            }
        }
    }, []);


    /**
     * Если есть данные о ранее загруженных файлах,
     * записываем их в State для обработки загрузки файлов
     * JSON.parse(JSON.stringify(...)) используется для глубокого копирования ("deep copy"),
     * чтобы создать копию данных без связи с исходным массивом (объектом)
     * */
    useMemo( () => {
        // console.log("useMemo Runs")
        if(Array.isArray(initialData) && initialData.length > 0) {
            setUploadedFiles(JSON.parse(JSON.stringify(initialData)));
        }
    }, [initialData]);


    /**
     * Все принятые файлы (acceptedFiles) загружаем в Directual
     * */
    const onDropAccepted = useCallback((acceptedFiles) => {

        // console.log("useCallback for acceptedFiles");
        // console.log("Accepted files");
        // console.log(acceptedFiles);

        // console.log("acceptedFiles Length: ", acceptedFiles.length);
        // console.log("uploadedFiles Length: ", );
        // console.log("Maximum number of files: ", maxNumberOfFiles);

        /**
         * Необходимо провести проверку на количество загружаемых файлов.
         * Если общее количество файлов с учетом ранее загруженных превышает максимально допустимое (maxFiles),
         * то загрузку не производим, выводим сообщение, что достигнут лимит.
         * Необходимо либо удалить файлы, либо выбрать меньшее количество для загрузки
         * */
        if (isMultiple === true && (acceptedFiles.length > maxNumberOfFiles)) {

            setMaxNumberOfFilesError('Максимально допустимое количество файлов');
            console.log('Максимально допустимое количество файлов')

        } else {

            /**
             * Загружаем принятые файлы (acceptedFiles) в Directual
             * Перед загрузкой поверяем, что файлы есть
             * */
            if(acceptedFiles.length !== 0) {
                acceptedFiles.forEach( (file) => {

                    let formData = new FormData();
                    formData.append(
                        "file", file
                    );

                    if (file) {
                        setLoadingFile(true);
                        console.log("Uploading file ...");
                        api
                            .structure(fileStorage)
                            .setData(fileEndpoint, formData,
                                {
                                    sessionID: session,
                                })
                            .then((response) => {

                                // Response Example
                                // console.log(response.result[0].file);
                                // {originalFileName: 'wa.link_m201fc.png', urlLink: 'https://api.directual.com/fileUploaded/studylab/765b3007-472d-43a2-ac87-f383b4bad60b.png'}

                                if (isMultiple === true) {
                                    setUploadedFiles(prevArray => [...prevArray, response.result[0].file])
                                } else {
                                    setUploadedFiles([response.result[0].file]);
                                }

                                setFileStorageResponse(response.result);
                                setFileStorageStatus(response.status);
                                setLoadingFile(false);

                            })
                            .catch((e) => {
                                setLoadingFile(false);
                                console.log(e);
                                setFileStorageBadRequest({
                                    httpCode: e.response.status,
                                    msg: e.response.data.msg
                                })
                            })
                    } else {
                        setLoadingFile(false);
                    }
                });
            }
        }
    }, [])

    const { acceptedFiles, getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        multiple: isMultiple,
        maxFiles: isMultiple === true? 5 : 0,
        maxSize: maxFileSize,
        onDropAccepted
    });


    useMemo( () => {
        // console.log("Result Files Update");
        // console.log(uploadedFiles.length);
        setResultFiles(uploadedFiles);
    }, [uploadedFiles]);


    const rejectedFiles = fileRejections.map(({ file, errors }) => (
        <div key={file.name} className="flex flex-row min-w-full w-auto text-red-700 dark:text-red-500 text-xs pt-0.5 grid-cols-2">
            <div className="w-1/5">
                <p className="truncate">{file.name} [{Math.floor(file.size / 1024 / 1024)}&nbsp;Mb]</p>
            </div>
            <div className="w-4/5 pl-4">
                {errors.map(e => (
                    <p key={e.code} className="pb-1">
                        {e.code === "file-too-large"
                            ? `The file size (~${Math.floor(file.size / 1024 / 1024)}\u00A0Mb) exceeds the allowed limit of ${Math.floor(maxFileSize / 1024 / 1024)}\u00A0Mb. Please use file reducer tools like ilovepdf.com or\u00A0compresspng.com`
                            : e.message
                        }
                    </p>
                ))}
            </div>
        </div>
    ));

    const removeFile = (index) => {
        uploadedFiles.splice(index, 1);
        setUploadedFiles([...uploadedFiles]);
    }

    return (
        <>
            <div {...getRootProps({
                className: "dark:bg-slate-900 hover:dark:bg-slate-800 bg-slate-50 hover:bg-slate-100 transition duration-300 rounded-lg border border-indigo-800 border-1 dark:border-indigo-300 border-dashed cursor-pointer text-center"
            }
            )}>
                <input {...getInputProps()} />
                <p className="text-sm text-center py-8 dark:text-indigo-500 text-indigo-800 inline-flex gap-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"/>
                    </svg>
                    {!isDragActive
                        ? <span>Drag & drop or <span className="underline">click to select</span></span>
                        : <span>There you go!</span>
                    }
                </p>
            </div>

            <div className="text-xs text-right pt-2 pr-2 dark:text-indigo-300 text-indigo-800">
                {isMultiple === true
                    ? <p>Multiple files are accepted | No more than {maxNumberOfFiles} files | Max file size: {Math.floor(maxFileSize / 1024 / 1024)}&nbsp;Mb</p>
                    : <p>One file is accepted | Max file size: {Math.floor(maxFileSize / 1024 / 1024)}&nbsp;Mb</p>
                }
            </div>

            {/*
            <p className="pl-4 text-white text-xs">Uploaded Files Length: {uploadedFiles.length}</p>
            */}

            <div className="pl-4">
                <h4 className="text-green-700 dark:text-green-500 pt-2 pb-0.5 text-xs font-bold">
                    {uploadedFiles.length === 0
                        ? loadingFile === true
                            ?
                                <span className="inline-flex">
                                    <span>Uploading {acceptedFiles.length === 1? "file" : `${acceptedFiles.length} files`}</span>
                                    <svg aria-hidden="true"
                                         className="inline w-3 h-3 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                </span>
                            : isMultiple === true
                                ? "Files not attached."
                                : "File not attached."
                        : loadingFile === true
                            ?
                                <span className="inline-flex">
                                    <span>Uploading {acceptedFiles.length === 1? "file" : `${acceptedFiles.length} files`}</span>
                                    <svg aria-hidden="true"
                                         className="inline w-3 h-3 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                </span>
                            : `Uploaded ${uploadedFiles.length === 1? "file" : "files"} [${uploadedFiles.length}]`
                    }
                </h4>
            </div>


            {uploadedFiles.length > 0 &&
                <div className="pl-4">
                    {/*
                    <h4 className="text-green-700 dark:text-green-500 pt-2 pb-0.5 text-xs font-bold">
                        {uploadedFiles.length > 1
                            ? `Uploaded files [${uploadedFiles.length}]:`
                            : "Uploaded file [1]:"
                        }
                    </h4>
                    */}
                    <div className="flex flex-wrap items-center gap-x-2 gap-y-1 py-0.5">
                        {/* [{originalFileName : "", urlLink : "" }] */}
                        {uploadedFiles.map((file, index) =>
                            <div key={index} className="flex flex-nowrap items-center items-stretch">
                                <a className="underline bg-green-400 py-0.5 rounded-bl-md rounded-tl-md text-xs"
                                   download={file.originalFileName} href={file.urlLink} target="_blank" rel="noreferrer">
                                    <span className="px-2">{file.originalFileName}</span>
                                </a>
                                <a className="bg-red-600 hover:bg-red-700 text-white py-0.5
                            rounded-br-md px-2 rounded-tr-md cursor-pointer flex items-center justify-center"
                                   onClick={() => removeFile(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-3 h-3">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                    </svg>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            }

            {rejectedFiles.length > 0 &&
                <div className="pl-4">
                    <h4 className="text-red-700 dark:text-red-500 pt-4 text-xs font-bold">
                        {rejectedFiles.length > 1
                            ? "Rejected files:"
                            : "Rejected file:"
                        }
                    </h4>
                    {rejectedFiles}
                </div>
            }

            {maxNumberOfFilesError.length > 0 &&
                <div className="pl-4">
                    <h4 className="text-red-700 dark:text-red-500 pt-4 text-xs font-bold">
                        {maxNumberOfFilesError}
                    </h4>
                </div>
            }
            {/*
            <div className="pl-4 pb-4">
                <h4 className="text-yellow-600 pt-4 text-sm">Original Files</h4>
                <ul className="text-slate-300 text-xs">{JSON.stringify(initialData)}</ul>
            </div>
            */}
        </>
    );
}

export default FileUpload;