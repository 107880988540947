import React from 'react';
import { useNavigate } from "react-router-dom";
import LogoutButton from "../../components/ui/LogoutButton";

function Unauthorized() {

    let navigate = useNavigate();

    return (
        <section className="bg-white min-h-screen dark:bg-gray-900">
            <div>
                <div className="bg-slate-50 min-h-screen dark:bg-slate-900">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                        <p className="mb-4 text-2xl font-bold text-indigo-600 md:text-4xl">
                            Unauthorized.
                        </p>
                        <p className="mb-4 text-lg font-light text-gray-500 dark:text-slate-400">
                            Sorry, you don't have permissions to view this page.
                        </p>

                        <div className="flex flex-row max-w-screen-sm gap-x-4">
                            <div className="w-1/2">
                                <button
                                    onClick={ () => navigate(-1)}
                                    className="w-full py-2.5 px-5 mr-2 mb-2
                                    text-sm font-medium text-white bg-indigo-600 rounded-lg text-center
                                    hover:bg-indigo-700
                                    focus:ring-4 focus:outline-none focus:ring-indigo-300
                                    dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">
                                    Go back
                                </button>
                            </div>
                            <div className="w-1/2">
                                <LogoutButton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Unauthorized;