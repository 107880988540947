import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";

function BottomNavigation() {

    const auth = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false)
    }, [auth.user]);

    const logout = () => {
        setLoading(true);
        auth.signout( () => navigate("/login") );
    }

    return (
        <section className="block h-14 fixed inset-x-0 bottom-0 z-10 dark:bg-slate-900 bg-slate-100
        border-t-2 dark:border-slate-300 border-slate-500 shadow ">
            <div className="flex flex-row-reverse justify-between items-center h-full">

                {/* HOME */}
                <div className="w-1/3 h-full flex justify-center text-center">
                    <NavLink to="/"
                             className={({ isActive }) => !isActive ? "group content-center w-full h-full flex justify-center items-center text-sm font-normal text-slate-900 hover:bg-slate-200 dark:text-slate-300 dark:hover:text-white dark:hover:bg-slate-700"
                                 : "group content-center w-full h-full flex justify-center items-center text-sm font-normal text-black bg-indigo-100 dark:text-white dark:bg-slate-700"}>
                        <svg aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5" stroke="currentColor"
                             className="w-4 h-4 text-black transition duration-300
                                             dark:text-slate-300 group-hover:text-black dark:group-hover:text-white">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
                        </svg>
                        <p className="pl-2 whitespace-nowrap transition duration-300">Home</p>
                    </NavLink>
                </div>

                {/* BRITISH SCHOOL */}
                <div className="w-1/3 h-full flex justify-center text-center">
                    <NavLink to="/british"
                             className={({ isActive }) => !isActive ? "group content-center w-full h-full flex justify-center items-center text-sm font-normal text-slate-900 hover:bg-slate-200 dark:text-slate-300 dark:hover:text-white dark:hover:bg-slate-700"
                                 : "group content-center w-full h-full flex justify-center items-center text-sm font-normal text-black bg-indigo-100 dark:text-white dark:bg-slate-700"}>
                        <svg aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5" stroke="currentColor"
                             className="w-4 h-4 text-slate-900 transition duration-300
                                             dark:text-slate-300 group-hover:text-black dark:group-hover:text-white">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"/>
                        </svg>
                        <p className="whitespace-nowrap pl-2 transition duration-300">BS</p>
                    </NavLink>
                </div>


                {/* SIGN OUT BUTTON */}
                <div className="w-1/3 h-full flex justify-center">
                    {!loading

                        ?

                        <button type="button" onClick={logout}
                                className="w-full text-center text-sm font-normal
                                text-slate-900 bg-slate-100
                                hover:bg-white hover:text-indigo-700
                                dark:bg-slate-900 dark:text-slate-300
                                dark:hover:text-white dark:hover:bg-slate-700">
                            Sign Out
                        </button>

                        :

                        <button disabled type="button"
                                className="w-full text-center text-sm font-normal
                                text-slate-900 bg-slate-100
                                hover:bg-white hover:text-blue-700
                                dark:bg-slate-800 dark:text-slate-300
                                dark:hover:text-white dark:hover:bg-slate-700
                                inline-flex items-center justify-center cursor-not-allowed">
                            <svg aria-hidden="true" role="status"
                                 className="inline mr-2 w-4 h-4 text-slate-200 animate-spin dark:text-slate-300"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#1C64F2"/>
                            </svg>

                        </button>
                    }
                </div>

            </div>
        </section>
    );
}

export default BottomNavigation;