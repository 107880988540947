import React from 'react';
import { useNavigate } from "react-router-dom";

function Missing() {

    let navigate = useNavigate();

    return (
        <section className="bg-white min-h-screen dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600
                    dark:text-primary-500">
                        404
                    </h1>
                    <p className="mb-4 text-2xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                        Something's missing.
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                        Sorry, we can't find that page.
                    </p>
                    <button
                        onClick={ () => navigate(-1)}
                        className="text-white mr-2 mb-2 bg-primary-600 hover:bg-primary-700
                                focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg
                                font-medium text-sm px-5 py-2.5 text-center
                                dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Go back
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Missing;