import React from 'react';
import { Link } from "react-router-dom";

function HomeActiveServiceBsCard({ academicName, academicYear, student, clientId, groupId }) {
    return (
        <>
            <Link to={`/british/${clientId}&groupID=${groupId}`} className="block p-6 max-w-sm aspect-[16/9] shadow-md rounded-lg
                flex flex-col justify-end transition duration-300
                text-white bg-indigo-900 hover:bg-indigo-800
                dark:text-white dark:bg-indigo-900 dark:border-indigo-800 dark:hover:bg-indigo-800">

                <h5 className="mb-0 pb-0 pt-4 text-xl font-bold text-white dark:text-white">
                    {academicName}
                </h5>

                <h5 className="mb-2 pt-0 pb-2 text-xl font-bold text-white dark:text-white">
                    {academicYear}
                </h5>

                <p className="font-base text-sm">
                    <span className="bg-orange-500 text-white dark:bg-orange-500 dark:text-white py-1 px-4
                    rounded-full">
                        {student}
                    </span>
                </p>

            </Link>
        </>
    );
}

export default HomeActiveServiceBsCard;