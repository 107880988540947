import { Outlet } from 'react-router-dom'
import TeacherSidebar from "../../components/ui/teacher/TeacherSidebar";
import React from "react";

const TeacherLayout = () => {
    return (
        <div className="flex bg-white dark:bg-slate-900">
            <TeacherSidebar />
            <div className="p-6 w-full">
                <Outlet />
            </div>
        </div>
    )
}

export default TeacherLayout;
