import { Outlet } from 'react-router-dom'
import ClientSidebar from "../../components/ui/client/ClientSidebar";
import React from "react";
import BottomNavigation from "../../components/ui/client/BottomNavigation";

const ClientLayout = () => {
    return (
        <div className="flex bg-slate-50 dark:bg-slate-900 h-full min-h-screen">

            {/*
                    'sm': '640px',
                    'md': '768px',
                    'lg': '1024px',
                    'xl': '1280px',
                    '2xl': '1536px'
             */}

            <div className="hidden lg:block">
                <ClientSidebar />
            </div>
            <div className="flex flex-col w-full">
                <div className="p-6">
                    <Outlet />
                </div>
                <div className="w-full lg:hidden">
                    <BottomNavigation />
                </div>
            </div>
        </div>
    )
}

export default ClientLayout;
