import { createContext, useState, useContext, useEffect } from "react";

import Directual from 'directual-api';

const api = new Directual({apiHost: '/'});
// const api = new Directual({apiHost: 'https://api.directual.com/'});

const AuthContext = createContext({});

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

    const auth = useProvideAuth();

    return (
        <AuthContext.Provider value={ auth }>
            {children}
        </AuthContext.Provider>
    )
}

// Provide hook that creates auth object and handles state
function useProvideAuth() {

    const [user, setUser] = useState(null);
    const [sessionID, setSessionID] = useState(null);
    const [role, setRole] = useState(null);

    const login = (username, password) => {
        return api.auth.login(username, password).then(res => {
            setUser(res.username)
            setSessionID(res.sessionID)
            setRole(res.role)

            // console.log("LOGIN")
            // console.log(res.sessionID)

            window.localStorage.setItem('sid', res.sessionID)
        })
    };

    const signout = (cb) => {
        return api.auth.logout('').then(res => {
            setUser(null)
            setRole(null)
            setSessionID(null)
            window.localStorage.setItem('sid', null)
            cb()
        })
    };

    const isAutorised = () => {
        return !!user
    }

    const hasRole = (roleCheck) => {
        if(!roleCheck){
            return true
        }
        return role === roleCheck
    }

    useEffect( () => {
        let sid = window.localStorage.getItem('sid') || ''

        // console.log("Running useEffect in AuthProvider");
        // console.log(sid);

        api.auth.isAuthorize(sid, (status, token)=>{
            if(status === true){

                // console.log("token")
                // console.log(token);

                setUser(token.username)
                setSessionID(token.token)
                setRole(token.role)
            }
        })
    }, []);

    return {
        user,
        sessionID,
        login,
        isAutorised,
        signout,
        hasRole
    };
}

export default AuthContext;