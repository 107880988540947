import React, {useEffect, useState} from 'react';
import { useData } from "../../../context/DataProvider";
import Directual from "directual-api";
import { useAuth } from "../../../context/AuthProvider";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import TeacherLessons from "./TeacherLessons";

const api = new Directual({apiHost: '/'});

function TeacherBritishSchool() {

    const pageSizeApiResponse = 400;

    const lessonsDataStructure = "bs_lessons";
    const lessonEndpoint = "getLessonsbyTeacher";

    const data = useData();
    const auth = useAuth();

    const [loading, setLoading] = useState(false);
    const [lessons, setLessons] = useState([]);
    const [lessonsToRegister, setLessonsToRegister] = useState([]);
    const [availableToRegister, setAvailableToRegister] = useState([]);
    const [registeredLessons, setRegisteredLessons] = useState([]);
    const [filtering, setFiltering] = useState(false);

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const setActiveTab = (index) => {
        setActiveTabIndex(index);
    }

    useEffect( () => {

        setLoading(true);

        if (data.teacherID) {

            api
                .structure(lessonsDataStructure)
                .getData(lessonEndpoint, {
                    teacherID: data.teacherID,
                    sessionID: auth.sessionID,
                    pageSize: pageSizeApiResponse
                })
                .then((response) => {

                    if (response.payload.length === 0) {
                        throw "Something went wrong: getLessonsByTeacher error. Missing important query parameters: user or sessionID."
                    }

                    setLessons(response.payload || []);
                    setLoading(false);

                })
                .catch((e) => {

                    console.log(e);
                    setLoading(false);

                })
        }
    }, [])

    useEffect( () => {

        // const filteredLessons = lessons.filter( lesson => lesson.registered === true);
        setRegisteredLessons(
            lessons.filter( lesson => lesson.registered === true)
        );

        setLessonsToRegister(
            lessons.filter( lesson => lesson.registered !== true)
        );

        setAvailableToRegister(
            lessonsToRegister.filter ( lesson => lesson.starts_at <= timeNow)
        );


    }, [lessons]);

    const timeNow = new Date().getTime();

    const timeConverter = (unixTimestamp) => {
        return new Date(unixTimestamp).toLocaleTimeString("ru-RU", {
            timeZone: "Europe/Moscow",
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    return (
        <>
            <div className="mb-4 border-b border-slate-200 dark:border-slate-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                    <li className="mr-2">
                        <button
                            className={activeTabIndex === 0
                                ? "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 text-indigo-600 hover:text-indigo-600 dark:text-indigo-500 dark:hover:text-indigo-500 border-indigo-600 dark:border-indigo-500"
                                : "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 border-transparent text-slate-500 border-slate-100 hover:text-slate-600 hover:border-slate-300 dark:hover:text-slate-300 dark:border-transparent dark:text-slate-400 dark:border-slate-700"
                            }
                            onClick={() => setActiveTab(0)}
                        >
                            To Register&nbsp;
                            {availableToRegister && availableToRegister.length > 0 &&
                                <span className="dark:bg-slate-800 bg-slate-100 px-2 py-0.5 rounded-lg text-xs">
                                    {availableToRegister.length}
                                </span>
                            }
                        </button>
                    </li>
                    <li className="mr-2">
                        <button
                            className={activeTabIndex === 1
                                ? "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 text-indigo-600 hover:text-indigo-600 dark:text-indigo-500 dark:hover:text-indigo-500 border-blue-600 dark:border-indigo-500"
                                : "inline-block p-4 rounded-t-lg border-b-2 transition duration-300 border-transparent text-slate-500 border-slate-100 hover:text-slate-600 hover:border-slate-300 dark:hover:text-slate-300 dark:border-transparent dark:text-slate-400 dark:border-slate-700"
                            }
                            onClick={() => setActiveTab(1)}
                        >
                            Registered
                        </button>
                    </li>
                </ul>
            </div>
            <div>
                <div className={activeTabIndex === 0
                    ? ""
                    : "hidden transition duration-300"}
                >
                    <div>
                        {!loading
                            ? <TeacherLessons lessons = { lessonsToRegister } registered = { false } />
                            : <LoadingSpinner />
                        }
                    </div>
                </div>
                <div className={activeTabIndex === 1
                    ? ""
                    : "hidden transition duration-300"}
                >
                    <div>
                        {!loading
                            ? <TeacherLessons lessons = { registeredLessons.sort( (a, b) => b.starts_at - a.starts_at) } registered = { true } />
                            : <LoadingSpinner />
                        }
                    </div>
                </div>
            </div>

            {/*
            <p className="text-xs dark:text-white">{JSON.stringify(lessons[0])}</p>
            <p className="text-xs dark:text-white">{JSON.stringify(lessons[1])}</p>
            */}
        </>
    );
}

export default TeacherBritishSchool;