import React from 'react';
import BsCalendarDay from "./BsCalendarDay";

function BsCalendar({calendarUpcoming, setCalendarUpcoming, calendarData, trialDate}) {

    return (
        <div className="w-full pb-12">
            <label htmlFor="small-toggle" className="inline-flex relative items-center mt-5 mb-5 cursor-pointer">
                <input type="checkbox" value={calendarUpcoming} onChange={ () => setCalendarUpcoming(!calendarUpcoming)}
                       id="small-toggle" className="sr-only peer"
                       checked={calendarUpcoming}
                />
                    <div
                        className="w-9 h-5 bg-slate-200 peer-focus:outline-none peer-focus:ring-4
                        peer-focus:ring-indigo-300 dark:peer-focus:ring-indigo-800 rounded-full peer
                        dark:bg-slate-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                        after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                        after:bg-white after:border-slate-300 after:border after:rounded-full
                        after:h-4 after:w-4 after:transition-all dark:border-slate-600 peer-checked:bg-indigo-600">
                    </div>
                    <span className="ml-3 text-sm font-base text-slate-900 dark:text-slate-300">
                        Show upcoming lessons only
                    </span>
            </label>

            {calendarData.length === 0?
                <div
                    className="p-4 text-sm text-slate-700 bg-slate-100 rounded-lg
                                dark:bg-slate-700 dark:text-slate-300">
                    There are no upcoming lessons.
                </div>
                : calendarData.sort( (a, b) => a.date - b.date).map((day) =>
                    <BsCalendarDay key = {day.id}
                                   dayData = {day}
                                   trialDate = {trialDate} />
                )
            }

        </div>
    );
}

export default BsCalendar;