import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

const RequireAuth = ({ allowedRole }) => {

    const auth = useAuth();
    const location = useLocation();

    return (
        auth.hasRole( allowedRole )
            ? <Outlet />
            : auth.isAutorised()
                ? <Navigate to="/unauthorized" state={{ from: location}} replace />
                : <Navigate to="/login" state={{ from: location}} replace />
    );
}

export default RequireAuth;