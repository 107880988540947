import React from 'react';
import TeacherLesson from "./TeacherLesson";

function TeacherLessons({ lessons, registered }) {
    return (
        <div className="flex flex-col items-center">
            {lessons.map( (lesson) =>
                    <TeacherLesson key={lesson.id} lesson={ lesson } registered = { registered } />
                )}
        </div>
    );
}

export default TeacherLessons;